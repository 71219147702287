import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import { useDispatch } from 'react-redux';
import { openCustomModal } from '../../../../actions/modal';
import { getWalletDetailsById } from '../../../../actions/wallet';
import { SkeltonCardTwo } from '../../../../common/SkeltonCardTwo';
import { getUserBankDetailsById } from '../../../../actions/auth';
import { validateNumberValue, validateStringValue } from '../../../../utils/common'
import SendToBank from '../../../Wallet/Modals/SendToBank';
import { isCluster, isCustomerGroup, isFe, isIsp, isPsp, isScm, isSp, isSpIsp, isSuperVisor } from '../../../../utils/role';
import { getTicketOwner } from '../../../Ticket/TicketController';


export default function TicketBankTransferPayment({ ticketId, rowPaymentsData }) {
    const { ticketDetails } = useSelector((state) => state.tickets);
    const dispatch = useDispatch();


    const onBankTransferClick = (e) => {
        dispatch(
            openCustomModal({
                heading: 'Direct Bank/UPI Transfer',
                modalWidth: '50vw',
                modalHeight: 'auto',
                modalComponent: <WalletDetails ticketDetails={ticketDetails} rowPaymentsData={rowPaymentsData} />
            })
        );
    }

    return <div className=''>
        <ButtonScogoPrimary
            textOrComponent={'Direct Bank/UPI Transfer'}
            onClick={(e) => onBankTransferClick(e)}
            loading={false}
            disabled={false}
            buttonIconComponent={<span className='material-icons text-xl'>account_balance</span>}
        />
    </div>
}

export const bank = 'bank';
export const upi = 'upi';


export const bankDetailPayload = ({ userId, role, type, isPo, vendorId }) => {
    const payload = { id: userId };

    if (isFe(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isScm(role, type)) {
        payload.check_user = false
    }
    const isUserSpPspSpisp = (isSp(role, type) || isPsp(role, type) || isSpIsp(role, type));
    const isUserIsIsp = isIsp(role, type);
    if (isUserSpPspSpisp || isUserIsIsp) {
        payload.type = 'SP'
    }

    if (isPo) {
        payload.type = 'ISP';
        payload.id = vendorId;
    }

    return payload;
}

const WalletDetails = ({ ticketDetails, rowPaymentsData }) => {
    const [selectedUser, setSelectedUser] = useState();
    const dispatch = useDispatch();
    const { userWalletDetailsById } = useSelector((state) => state.wallet);
    const { loggedUser } = useSelector((state) => state.auth);
    const { userBankDetail } = useSelector((state) => state.user);
    const { userWalletDetailsByIdLoading } = useSelector((state) => state.loading);
    const { isBankDetailsLoading } = useSelector((state) => state.loading);
    const [paymentType, setPaymentType] = useState(bank);


    const memoizedUserList = useMemo(() => {

        function getSum(data, key) {
            if (data.length === 0) return 0;
            if (!key) return 0;
            // supervisor_details
            // warehouse_details
            // psp_details
            // partner_details
            return data.reduce((sum, item) => {
                if (item.status !== "isRejected" && item.type !== 'WITHDRAW') {
                    return sum + item[key];
                }
                return sum;
            }, 0);
        }

        let users = [];
        if (ticketDetails) {
            const isTicketOwnerIsCustomer = isCustomerGroup(loggedUser.role.id, loggedUser.type) && getTicketOwner({ ticket_owned_by: ticketDetails.ticket_owned_by, assign_to_scogo: ticketDetails.assign_to_scogo }) === 'Customer';
            const { accepted_sp_id, sp_first_name, sp_last_name, cluster_id, cl_details, supervisor_id, supervisor_details, warehouse_owner, warehouse_owner_details, psp_id, psp_details, additional_amount_paid, additional_incentive, psp_additional_incentive, supervisor_amount_paid, warehouse_expenses, accepted_s_p_company_detail } = ticketDetails;

            // Service Partner
            if (accepted_sp_id > 0 && (!isCustomerGroup(loggedUser.role.id, loggedUser.type) || isTicketOwnerIsCustomer)) {
                users.push({
                    name: `${validateStringValue(sp_first_name)} ${validateStringValue(sp_last_name)}`,
                    label: `SP : ${validateStringValue(sp_first_name)} ${validateStringValue(sp_last_name)}`,
                    id: accepted_sp_id,
                    max_amount: additional_amount_paid,
                    role: accepted_s_p_company_detail?.role,
                    type: accepted_s_p_company_detail?.type,
                    service_partner_id: accepted_s_p_company_detail?.service_partner_id,
                    customer_id: accepted_s_p_company_detail?.customer_id,
                    ticket_allowed_amount: getSum(rowPaymentsData, 'partner_details')
                })
            }

            // Cluster
            if (cluster_id > 0 && (!isCustomerGroup(loggedUser.role.id, loggedUser.type) || isTicketOwnerIsCustomer)) {
                users.push({
                    name: `${validateStringValue(cl_details?.first_name)} ${validateStringValue(cl_details?.last_name)}`,
                    label: `Cluster : ${validateStringValue(cl_details?.first_name)} ${validateStringValue(cl_details?.last_name)}`,
                    id: cl_details?.id,
                    max_amount: additional_incentive,
                    role: cl_details?.role,
                    type: cl_details?.type,
                    service_partner_id: cl_details?.service_partner_id,
                    customer_id: cl_details?.customer_id,
                    ticket_allowed_amount: getSum(rowPaymentsData, 'cluster_details')
                })
            }

            // PSP
            if (psp_id > 0 && (!isCustomerGroup(loggedUser.role.id, loggedUser.type) || isTicketOwnerIsCustomer)) {
                users.push({
                    name: `${validateStringValue(psp_details?.first_name)} ${validateStringValue(psp_details?.last_name)}`,
                    label: `PSP : ${validateStringValue(psp_details?.first_name)} ${validateStringValue(psp_details?.last_name)}`,
                    id: psp_id,
                    max_amount: psp_additional_incentive,
                    role: psp_details?.role,
                    type: psp_details?.type,
                    service_partner_id: psp_details?.service_partner_id,
                    customer_id: psp_details?.customer_id,
                    ticket_allowed_amount: getSum(rowPaymentsData, 'psp_details')
                })
            }

            // Supervisor
            if (supervisor_id > 0 && (!isCustomerGroup(loggedUser.role.id, loggedUser.type) || isTicketOwnerIsCustomer)) {
                users.push({
                    name: `${validateStringValue(supervisor_details?.first_name)} ${validateStringValue(supervisor_details?.last_name)}`,
                    label: `Supervisor : ${validateStringValue(supervisor_details?.first_name)} ${validateStringValue(supervisor_details?.last_name)}`,
                    id: supervisor_id,
                    max_amount: supervisor_amount_paid,
                    role: supervisor_details?.role,
                    type: supervisor_details?.type,
                    service_partner_id: supervisor_details?.service_partner_id,
                    customer_id: supervisor_details?.customer_id,
                    ticket_allowed_amount: getSum(rowPaymentsData, 'supervisor_details')
                })
            }

            // Warehouse Owner
            if (warehouse_owner > 0 && (!isCustomerGroup(loggedUser.role.id, loggedUser.type) || isTicketOwnerIsCustomer)) {
                users.push({
                    name: `${validateStringValue(warehouse_owner_details?.first_name)} ${validateStringValue(warehouse_owner_details?.last_name)}`,
                    label: `Warehouse Own : ${validateStringValue(warehouse_owner_details?.first_name)} ${validateStringValue(warehouse_owner_details?.last_name)}`,
                    id: warehouse_owner,
                    max_amount: warehouse_expenses,
                    role: warehouse_owner_details?.role,
                    type: warehouse_owner_details?.type,
                    service_partner_id: warehouse_owner_details?.service_partner_id,
                    customer_id: warehouse_owner_details?.customer_id,
                    ticket_allowed_amount: getSum(rowPaymentsData, 'warehouse_details')
                })
            }
            users = users.filter(({ id }) => id !== loggedUser.id)
        }
        return users
    }, [ticketDetails, loggedUser.id, loggedUser.role.id, loggedUser.type, rowPaymentsData]);

    const userWalletDetails = useMemo(() => {
        if (selectedUser && selectedUser.id && userWalletDetailsById[selectedUser.id]) {
            return userWalletDetailsById[selectedUser.id];
        }
        return {}
    }, [userWalletDetailsById, selectedUser]);

    const memoizedUserBankDetails = useMemo(() => {
        if (selectedUser && selectedUser.id && userBankDetail[selectedUser.id]) {
            return userBankDetail[selectedUser.id];
        }
        return {}
    }, [userBankDetail, selectedUser]);

    useEffect(() => {
        return () => setSelectedUser();
    }, []);

    const onUserSelect = (u) => {
        setSelectedUser(u);
        const payload = bankDetailPayload({ userId: u.id, role: u.role, type: u.type });
        dispatch(getUserBankDetailsById(payload));
    }

    useEffect(() => {
        let account_balance = 'BANKOPEN';
        if (paymentType === upi) account_balance = 'ZWITCH'
        if (selectedUser && !userWalletDetailsByIdLoading) {
            dispatch(getWalletDetailsById({ updateBalance: false, beneficiary: selectedUser.id, accountBalance: account_balance, source: 'ticket_bank_transfer' }));
        }
    }, [selectedUser, paymentType, dispatch])

    useEffect(() => {
        if (memoizedUserList && memoizedUserList.length === 1) {
            setSelectedUser(memoizedUserList[0]);
            onUserSelect(memoizedUserList[0]);
        }
    }, [memoizedUserList]);

    if (memoizedUserList.length === 0) {
        return <span className='font-medium text-scogoorange px-8 py-8' style={{ fontSize: '1.5rem' }}>No Users To Pay</span>
    }

    const amountValidationCheck = (amount) => {
        amount = amount && parseInt(amount);
        let minAmountCheck = 400;
        if (isCustomerGroup(loggedUser.role.id, loggedUser.type)) {
            minAmountCheck = 100
        }
        if (loggedUser.per_transaction_limit !== -1) {
            return loggedUser.per_transaction_limit >= amount || 'You have exceeded your per-transaction limit.';
        }
        return parseFloat(amount) >= minAmountCheck && amount <= selectedUser.max_amount || `The amount should be between ${minAmountCheck} and the user\'s maximum allowed limit.`;
    }

    const isUserSpPspSpisp = selectedUser && (isSp(selectedUser.role, selectedUser.type) || isPsp(selectedUser.role, selectedUser.type) || isSpIsp(selectedUser.role, selectedUser.type));
    const isUserIsIsp = selectedUser && isIsp(selectedUser.role, selectedUser.type);

    let vendorId = selectedUser && selectedUser.id;
    let vendorType = '';
    if (isUserSpPspSpisp && selectedUser) {
        vendorId = selectedUser.service_partner_id;
        vendorType = 'SP'
    }
    if (isUserIsIsp && selectedUser) {
        vendorId = selectedUser.customer_id;
        vendorType = 'ISP';
    }



    return (
        <div className="w-full h-full px-5 py-4 bg-gray-100">
            <div className="flex gap-3 flex-wrap justify-start mb-5">
                {memoizedUserList.map((user, index) => (
                    <span
                        onClick={() => onUserSelect(user)}
                        key={index}
                        className={`${selectedUser && selectedUser.id === user.id && selectedUser.name === user.name ? 'bg-blue-400 text-white' : 'bg-blue-200'} text-md flex items-center rounded-xl mb-2 px-6 py-3 hover:bg-blue-400 hover:text-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-105`}
                    >
                        {user.label}
                    </span>
                ))}
            </div>

            {selectedUser && (
                <div className="bg-white shadow-lg rounded-lg p-5 transition-all duration-300 ease-in-out transform ">
                    {
                        userWalletDetailsByIdLoading ? <div className='px-4'>
                            <SkeltonCardTwo arr={[1, 2, 3, 4]} height={20} />
                        </div> :
                            <SendToBank selectedUser={selectedUser} isShowDailyLimit={userWalletDetails?.withdraw_limit && userWalletDetails?.withdraw_limit !== 0} wallet={userWalletDetails} isBankDetailsLoading={isBankDetailsLoading} bankDetails={memoizedUserBankDetails} directBankTransfer vendorId={vendorId} userId={selectedUser.id} scogoBankAccountBalance={validateNumberValue(userWalletDetails?.scogo_bankopen_account_balance)} showUpiTransefer ticketId={ticketDetails.id} amountValidationCheck={amountValidationCheck} isUserSpPspSpisp={isUserSpPspSpisp} isUserIsIsp={isUserIsIsp} type={vendorType} paymentType={paymentType} setPaymentType={setPaymentType} ticket_allowed_amount={selectedUser.ticket_allowed_amount} showTotalWithdrawn={false} />
                    }
                </div>
            )}
        </div>
    );
};