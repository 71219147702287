import {
    isScm,
    isCluster,
    isCustomer,
    isCustomerAdmin,
    isCustomerUser,
    isFe,
    isSp,
    isPsp,
    isIsp,
    isEndUser,
    isSuperVisor,
    isSpIsp,
} from '../../utils/role';
import { useSelector } from 'react-redux';

export const GetWalletLogic = (logic, { wallet, totalPaid, transaction } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type,
        userId = loggedUser.id;

    if (logic === 'isToShowAddMoneyButton') return !isCustomerUser(role, type);
    else if (logic === 'isToShowPayButton') return !isFe(role, type) && loggedUser.is_enabled === 1;
    else if (logic === 'isToShowSendToBankButton') return !isCustomerAdmin(role, type) && !isCustomerUser(role, type) && !isCustomer(role, type) && loggedUser.is_enabled === 1;
    else if (logic === 'isToShowTotalEarningAmountCard')
        return (
            isCluster(role, type) ||
            isSuperVisor(role, type) ||
            isFe(role, type) ||
            isSp(role, type) ||
            isPsp(role, type) ||
            isIsp(role, type) ||
            isSpIsp(role, type)
        );
    else if (logic === 'isToShowPromisedAmountCard') return isSp(role, type) || isPsp(role, type) || isIsp(role, type) || isSpIsp(role, type);
    else if (logic === 'isToShowReservedAmountCard')
        return isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type) || isEndUser(role, type);
    else if (logic === 'getPaidAmount') return getPaidAmount(role, type, { wallet, totalPaid });
    else if (logic === 'getCurrentBalance') return getCurrentBalance(role, type, { wallet });
    else if (logic === 'getReservedAmount') return getReservedAmount(role, type, { wallet });
    else if (logic === 'getCompanyWalletDetailsBalance') return getCompanyWalletDetailsBalance({ wallet });
    else if (logic === 'isToShowPayFeInPayButton') return isSp(role, type) || isIsp(role, type) || isSpIsp(role, type) || isPsp(role, type);
    else if (logic === 'isToShowPayScogoInPayButton') return isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type);
    else if (logic === 'getTransactionTitleOrType') return getTransactionTitleOrType(transaction, loggedUser.id);
    else if (logic === 'isToShowViewDetailsIcon') return getIsToShowViewDetailsIcon(transaction);
    else if (logic === 'isCustomerViewDetailsEnable') return isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type);
    else if (logic === 'isToShowPayFEInTransaction') return shouldShowPayFeInTransaction(role, type, { transaction, userId });
};

const shouldShowPayFeInTransaction = (role, type, { transaction, userId }) => {
    const isUserDifferentFromAcceptedFe = transaction.accepted_fe_id > 0 && transaction.accepted_fe_id !== userId;
    return (
        isSp(role, type) &&
        transaction.paymentType === paymentType.ticket_payment &&
        transaction.ticketId > 0 &&
        isUserDifferentFromAcceptedFe &&
        transaction.status === transactionStatus.success_po
    );
};

const getPaidAmount = (role, type, { wallet, totalPaid }) => {
    if (isCustomer(role, type) || isEndUser(role, type)) return wallet?.amount_used;
    if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) return wallet?.customerWallet?.amount_used
    if (isScm(role, type)) return totalPaid;
    return 0;
};

const getCurrentBalance = (role, type, { wallet }) => {
    let currentBalance = 0;
    if (wallet?.wallet_amount !== 0) {
        if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
            currentBalance = wallet?.customerWallet?.wallet_amount
        } else {
            currentBalance = wallet?.wallet_amount
        }
    }
    else if (wallet?.loan_amount) {
        if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
            currentBalance = `-${wallet.customerWallet?.loan_amount}`
        } else {
            currentBalance = `-${wallet.loan_amount}`
        }
    };
    return currentBalance;
};

const getReservedAmount = (role, type, { wallet }) => {
    let reservedAmount = 0;

    if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
        reservedAmount = wallet?.customerWallet?.locked_amount
    } else {
        reservedAmount = wallet?.locked_amount
    }
    if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
        reservedAmount = `${wallet?.customerWallet?.locked_amount}`
    } else {
        reservedAmount = `${wallet?.locked_amount}`
    }
    return reservedAmount;
};

const getCompanyWalletDetailsBalance = ({ wallet }) => {
    let balance = 0;
    if (wallet?.customerWallet?.wallet_amount > 0) balance = wallet.customerWallet.wallet_amount;
    else if (wallet?.customerWallet?.loan_amount > 0) balance = `-${wallet.customerWallet.loan_amount}`;
    return balance;
};

const getIsToShowViewDetailsIcon = (transaction) => {
    let isToShowViewDetails = false;
    const isWalletTransferType = transaction.paymentType === paymentType.wallet_transfer;
    const isTicketPaymentType = transaction.paymentType === paymentType.ticket_payment;
    const isIncetiveType = transaction.paymentType === paymentType.incentive;
    const isValidTicketId = transaction.ticketId > 0;
    if (
        (isWalletTransferType && reasons.includes(transaction.reason) && isValidTicketId) ||
        isTicketPaymentType ||
        (isIncetiveType && isValidTicketId)
    ) {
        isToShowViewDetails = true;
    }
    return isToShowViewDetails;
};

export const orderStatusCode = {
    withdraw_success: [15, '15'],
    withdraw_awaiting: [16, 20, 21, 22, 23, 38, 39, 44, 46, 47, 48, 56, 57, 59, 65, 68, 69, 83, 101, '16', '20', '21', '22', '23', '38', '39', '44', '46', '47', '48', '56', '57', '59', '65', '68', '69', '83', '101'],
    withdraw_rejected: [
        17,
        18,
        19,
        37,
        40,
        41,
        42,
        43,
        45,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        58,
        60,
        61,
        62,
        63,
        64,
        66,
        67,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        84,
        85,
        87,
        88,
        90,
        91,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
        100,
        102,
        352,
        '17',
        '18',
        '19',
        '37',
        '40',
        '41',
        '42',
        '43',
        '45',
        '49',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '58',
        '60',
        '61',
        '62',
        '63',
        '64',
        '66',
        '67',
        '70',
        '71',
        '72',
        '73',
        '74',
        '75',
        '76',
        '77',
        '78',
        '79',
        '80',
        '81',
        '82',
        '84',
        '85',
        '87',
        '88',
        '90',
        '91',
        '92',
        '93',
        '94',
        '95',
        '96',
        '97',
        '98',
        '99',
        '100',
        '102',
        '352'
    ],
    withdraw_failed: [17, '17']
};

export const paymentType = {
    purchase: 'PURCHASE',
    payment_gateway: 'PAYMENT_GATEWAY',
    withdraw: 'WITHDRAW',
    wallet_transfer: 'WALLET_TRANSFER',
    incentive: 'INCENTIVE',
    ticket_type: 'TICKET',
    ticket_payment: 'TICKET_PAYMENT',
    bank_transfer_credit: 'BANK_TRANSFER_CREDIT',
    bank_transfer_debit: 'BANK_TRANSFER_DEBIT',
    refund: 'REFUND',
    rewards: 'REWARDS',
    penalty: 'PENALTY',
    security_deposit: 'SECURITY_DEPOSIT',
};

export const transactionStatus = {
    paid: 'paid',
    captured: 'captured',
    failed: 'failed',
    cancelled: 'cancelled',
    late_authorized: 'late_authorized',
    attempted: 'attempted',
    approval_pending: '1',
    awaiting: '2',
    reject_po: '3',
    success_po: '4',
    success: 'Success',
    pending: 'Pending',
    failuresList: ['502', 502, '412', 412, '422', 422],
    scheduled: 'Scheduled',
    failure: 'Failure',
};

const txStatusCategories = {
    failureList: ['cancelled', 'failed', 'FAILED', 'Failure', 'Suspect', 'Canceled'],
    successList: ['captured', 'paid', 'SUCCESS'],
    attempted: 'attempted',
    locked: '1', // need to confirm meanig
    debit: '2', // need to confirm meanig
};

const reasons = ['MATERIAL', 'SERVICE', 'COURIER', 'TRAVEL', 'INCENTIVE', 'GST'];

export const amountType = {
    debit: 'DEBIT',
    credit: 'CREDIT',
    pending: 'PENDING',
    rejected: 'REJECTED',
    locked: 'LOCKED',
    awaiting: 'AWAITING',
    approval_pending: 'APPROVAL_PENDING',
};

const getTransactionTitleOrType = (transaction, loggedUserId) => {
    const walletTopupTitle = 'Wallet Topup';
    const walletTopupInProcessTitle = 'Wallet TopUp in Process';
    const recievedFromScogo = 'Received from Scogo';
    const pendingFromScogoTitle = 'Pending from Scogo';
    const transferredToBankTitle = 'Transferred to bank';
    const securityDepositPaid = 'Security Deposit Paid';
    const paidToScogoTitle = 'Paid To Scogo';
    const txStatusTitle = transaction?.txStatus && transaction?.txStatus?.toString();
    let transactionType = 'PENDING';
    let transactionTitle = txStatusTitle;

    const transactionPaymentType = transaction.paymentType;
    const transactionOrderStatus = transaction.orderStatus;
    const status = transaction.status;
    const txStatus = transaction.txStatus;

    switch (transactionPaymentType) {
        case paymentType.wallet_transfer: {
            if (transaction.recepient === loggedUserId) {
                transactionType = amountType.credit;
            }
            if (transaction.sender === loggedUserId) {
                transactionType = amountType.debit;
            }

            if (transactionStatus.failuresList.includes(status)) transactionType = amountType.rejected;

            if ([transactionStatus.pending, transactionStatus.scheduled].includes(status)) {
                transactionType = amountType.pending;
                transactionTitle = `${transactionTitle} (${status})`;
            }
            if (transactionStatus.failure === status) {
                transactionType = amountType.rejected;
                transactionTitle = `${transactionTitle} (Rejected)`;
            }

            break;
        }

        case paymentType.ticket_payment: {
            if (status === transactionStatus.success_po) {
                transactionType = amountType.credit;
                transactionTitle = recievedFromScogo;
            } else if (status === transactionStatus.reject_po) {
                transactionType = amountType.rejected;
            } else if (status === transactionStatus.awaiting) {
                transactionType = amountType.awaiting;
                transactionTitle = pendingFromScogoTitle;
            } else if (status === transactionStatus.approval_pending) {
                transactionType = amountType.approval_pending;
                transactionTitle = pendingFromScogoTitle;
            }
            break;
        }

        case paymentType.payment_gateway: {
            if (txStatusCategories.successList.includes(txStatus)) {
                transactionType = amountType.credit;
                transactionTitle = walletTopupTitle;
            } else if (txStatusCategories.failureList.includes(txStatus)) {
                transactionType = amountType.rejected;
            } else if (txStatusCategories.attempted === txStatus) {
                transactionTitle = walletTopupInProcessTitle;
            }
            break;
        }

        case paymentType.withdraw:
        case paymentType.bank_transfer_debit:
        case paymentType.bank_transfer_credit: {
            if (transactionStatus.failuresList.includes(status)) transactionType = amountType.rejected;

            if (orderStatusCode.withdraw_success.includes(transactionOrderStatus)) {
                transactionTitle = transferredToBankTitle;
                transactionType = amountType.debit;
                if (transactionPaymentType === paymentType.bank_transfer_credit) {
                    transactionType = amountType.credit;
                }
            }

            if (orderStatusCode.withdraw_awaiting.includes(transactionOrderStatus)) {
                transactionTitle = `Withdraw ${txStatusTitle}`;
            }

            if (orderStatusCode.withdraw_rejected.includes(transactionOrderStatus)) {
                transactionTitle = txStatusTitle || transaction.reason;
            }

            break;
        }

        case paymentType.refund:
        case paymentType.incentive:
        case paymentType.rewards: {
            transactionType = amountType.credit;
            break;
        }

        case paymentType.security_deposit: {
            transactionType = amountType.debit;
            transactionTitle = securityDepositPaid;
            break;
        }

        case paymentType.penalty: {
            transactionType = amountType.debit;
            break;
        }

        case paymentType.purchase: {
            const title = `Bought ${transaction.payload && transaction.payload.item_name} subscription`;
            transactionType = amountType.debit;
            transactionTitle = title;
            break;
        }

        case paymentType.ticket_type: {
            if (txStatusCategories.locked === txStatus.toString()) {
                transactionType = amountType.locked;
                transactionTitle = 'Reserved';
            } else if (txStatusCategories.debit === txStatus.toString()) {
                transactionType = amountType.debit;
                transactionTitle = paidToScogoTitle;
            }
            break;
        }

        default: {
            break;
        }
    }

    return { transactionType, transactionTitle };
};
