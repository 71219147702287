import { useDispatch, useSelector } from "react-redux"
import { copyToClipboard } from "../../actions/utils"
import IconToolTip from "../../common/IconToolTip"
import OverflowTip from "../../common/OverflowTip"
import { RibbonContainer } from "../../components/TicketExecution/RibbonContainer"
import { getAssetColor, validateStringValue } from "../../utils/common"
import { LineClampComponent } from "../Ticket/TicketTableComponent"
import moment from "moment"
import StatusLabel from "../Payments/reusableComponents/StatusLabel"
import { Skeleton } from "@mui/material"
import ButtonScogoOrange from "../../common/Buttons/ButtonScogoOrange"
import { updateSignOffSteps } from "../../actions/ticketExecution"
import { getSignOffSampleReport, getSOWDocument, getTicketReportLink } from "../../actions/tickets";
import { getPartnerBasePayment } from "../Ticket/TicketColumns/Price"
import { findAssetdublicetCount } from "../Ticket/TicketColumns/Asset"

const disabledClass = 'text-black flex items-center justify-center rounded-md gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd px-2 leading-6';


const MobileBasicInfo = ({ headerLabel, openTicketDetails, status }) => {
    const { isSowNotFoundLoading, isSignOffNotFoundLoading, isDownloadReportLoading, isViewTicketByIdLoading } = useSelector((state) => state.loading);
    let hoverText = status;
    const dispatch = useDispatch();
    const { id: ticketId, fk_sign_off_template_id, is_sign_off_request, site, job_site_address_1, city, state, description, created_at, execution_date, job_fk_pincode_id } = openTicketDetails;

    let ticket_status = { label: status, color: 'scogo2e' };

    const { loggedUser } = useSelector((state) => state.auth);
    const isToShowScogoReport = is_sign_off_request !== 0;
    const isToShowSignOffSample = is_sign_off_request === 0;

    const onSignOfflick = () => {
        if (fk_sign_off_template_id > 0) {
            dispatch(updateSignOffSteps({ ticketId, download: true }));
        }
        dispatch(getSignOffSampleReport(ticketId, false));
    };


    const scogoReport = () => {
        if (fk_sign_off_template_id > 0) {
            dispatch(updateSignOffSteps({ ticketId, download: true }));
        }
        dispatch(getTicketReportLink({ ticketId }));
    };

    const onSOWClick = () => {
        dispatch(getSOWDocument(ticketId, false));
    };

    const showPartnerPrice = openTicketDetails && (openTicketDetails?.accepted_sp_id === 0 || loggedUser?.id === openTicketDetails?.accepted_sp_id);

    const partner_base_payment = openTicketDetails && getPartnerBasePayment({ partner_base_payment: openTicketDetails.partner_base_payment, additional_expenses: openTicketDetails.additional_expenses, additional_amount_paid: openTicketDetails.additional_amount_paid, psp_id: openTicketDetails.psp_id, psp_base_payment: openTicketDetails.psp_base_payment, loggedUserPaymentRatio: loggedUser?.payment_ratio, ticketSpPaymentRatio: 1, loggedUserId: loggedUser?.id, acceptedSpId: openTicketDetails.accepted_sp_id, signOffAccept: openTicketDetails.is_signoff_accept })

    return (
        <RibbonContainer headerLabel={headerLabel}>
            {loggedUser && <div className='absolute right-4 flex gap-x-1 top-4'>
                <IconToolTip title='SOW'>
                    <span>
                        <ButtonScogoOrange disabledClass={disabledClass} loading={isSowNotFoundLoading} textOrComponent={'SOW'} onClick={onSOWClick} />
                    </span>
                </IconToolTip>
                {isToShowSignOffSample && <IconToolTip title='Sign Off Sample'>
                    <span>
                        <ButtonScogoOrange disabledClass={disabledClass} loading={isSignOffNotFoundLoading} textOrComponent={'Sign Off Sample'} onClick={onSignOfflick} />
                    </span>
                </IconToolTip>}
                {isToShowScogoReport &&
                    <IconToolTip title='Scogo Report'>
                        <span>
                            <ButtonScogoOrange disabledClass={disabledClass} loading={isDownloadReportLoading} textOrComponent={'Scogo Report'} onClick={scogoReport} />
                        </span>
                    </IconToolTip>}
            </div>}
            {isViewTicketByIdLoading ? (
                <div className='flex flex-col items-center justify-center pt-2 '>
                    <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={300} height={90} animation='wave' />

                </div>

            ) :
                <div className='w-full gap-x-2  gap-y-4 justify-around  col-span-2 mt-14'>
                    {/* {project?.project_name && (
                        <div className='py-2'>

                            <OverflowTip someLongText={` ${project?.project_name}`} textClass="text-font13 font-normal text-scogograytext-scogoprimary" />
                        </div>

                        )} */}
                    <TicketDiscription discription={description} />
                    {showPartnerPrice && <LineClampComponent textCss='text-font13 text-scogosuccess pt-2' label={'Reward'} value={`₹ ${partner_base_payment}`} labelClass='text-font13' />
                    }
                    <div className="pt-4 flex items-center gap-3 ">
                        <div className="text-font13 break-all text-scogogray font-medium">Status : </div>
                        <div className="">
                            <StatusLabel
                                borderColor={`border-${ticket_status.color}`}
                                content={ticket_status.label}
                                color={ticket_status.color}
                                shownNobgLabel={ticket_status?.shownNobgLabel}
                                labelClass={ticket_status?.labelClass}
                                hoverText={hoverText}
                                titleText={ticket_status.textTittle}
                            />
                        </div>

                    </div>

                    <div className="pt-4">
                        <span className="text-font13 break-all text-scogogray font-medium">Site Id : </span>
                        <span className="text-font13">  {site?.external_site_id}</span>
                    </div>
                    <BasicTicketAddress job_site_address_1={job_site_address_1} state_name={state?.state_name} city_name={city?.city_name} job_fk_pincode_id={job_fk_pincode_id} />

                    <div className='pt-4 flex flex-col  w-[100%]'>
                        <Created createdDate={created_at} execution_date={execution_date} />
                        {/* <TicketRemark remark={all_event_detail} /> */}
                        < RenderBoq assetsArray={openTicketDetails?.ticket_asset ? findAssetdublicetCount(openTicketDetails?.ticket_asset) : []} />
                    </div>
                </div>}
        </RibbonContainer>

    )
}
export default MobileBasicInfo;

export const TicketRemark = ({ remark }) => {
    return (
        <>
            <div className='pt-4'>
                <h3 className={'text-font13 break-all text-scogogray font-medium'}>Remark </h3>
                <div className='flex-wrap flex items-center line clamp-2 gap-x-2'>
                    {remark?.length > 0 ? (
                        <>
                            <div className='max-w-8/8 truncate text-font13'>
                                {remark[remark.length - 1].title}
                            </div>

                        </>
                    ) : (
                        <>No Remark</>
                    )}
                </div>
            </div>
        </>
    )
}


export const RenderBoq = ({ assetsArray }) => {
    const dispatch = useDispatch();
    if (!assetsArray || assetsArray?.length === 0) return <></>;

    return <div className='col-span-2'>
        <h3 className={'text-font13 break-all text-scogogray font-medium'}>BOQ </h3>

        <div className='flex-wrap flex items-center  gap-x-1 '>
            {assetsArray?.map((asset) => {
                const status = `${asset?.status?.length > 0 ? ` ${asset?.status}` : ''}`;
                const workingStatus = `${asset?.working_condition?.length > 0 ? `, ${asset?.working_condition}` : ''}`;
                return (
                    <IconToolTip title={`${status} ${workingStatus}`}>
                        <span className={`bg-${getAssetColor(asset)} cursor-pointer text-white max-w-12   text-font10 px-2 flex items-center rounded-3px mb-1`}>
                            <span className='max-w-4/5 truncate'  >
                                {(asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title)}
                            </span>
                            <span>
                                {(asset?.count > 1 ? `: ${asset?.count}` : '')}
                            </span>
                            <span className="material-icons text-font12 ml-1 hover:text-scogobgsky" onClick={(event) => {
                                event.stopPropagation();
                                const text = asset?.serial_number?.length > 0 ? asset?.serial_number : asset?.title

                                dispatch(copyToClipboard({ data: text }))
                            }}>
                                content_copy
                            </span>
                        </span>
                    </IconToolTip>
                );
            })}
        </div>
    </div>
}


export const Created = ({ createdDate, execution_date }) => {
    let date = execution_date;
    let label = 'Planned Date';
    if (!date) {
        date = createdDate;
        label = 'Created At'
    }

    return (
        <>
            <div className=" flex  gap-4">
                <p className='text-font13 break-all text-scogogray font-medium'>{label}</p>
                <span className='bg-scogoLogoGreen  text-white max-w-max text-font12 px-2 py-1 rounded-3px'>{moment(date).format('DD MMM YY')}</span>
            </div>
        </>
    )
}

export const TicketDiscription = ({ discription }) => {
    if (!discription) return <></>
    return (<>
        <div className='col-span-2 pt-4'>
            <p className='text-font13 break-all text-scogogray font-medium'>Description</p>
            <div className="text-font14"><LineClampComponent value={discription} /></div>
        </div>
    </>)
}

export const BasicTicketAddress = ({ job_site_address_1, state_name, city_name, job_fk_pincode_id }) => {
    const dispatch = useDispatch();
    return (
        <>
            <span className=' flex items-center gap-2 w-full pt-4 col-span-2 line-clamp-5'>
                <span className='text-font13 break-all text-scogogray font-medium' >Address :
                </span>
                <span className='text-font13 '>
                    {` ${validateStringValue(job_site_address_1)}, ${validateStringValue(city_name)}, ${validateStringValue(state_name)}, ${job_fk_pincode_id}`}
                </span>
                <IconToolTip placement={'bottom'} title={`Open Location`}>
                    <span className='cursor-pointer material-icons text-scogobgsky text-font18 md:text-xl' onClick={() => {
                        window.open(`https://maps.google.com?q=${`${job_site_address_1}, ${city_name}, ${state_name}, ${job_fk_pincode_id}`}`, '_blank');
                    }}>
                        place
                    </span>
                </IconToolTip>
                <span className='ml-auto px-2'>
                    <IconToolTip placement={'bottom'} title={`Copy Site Address`}>
                        <span
                            className='cursor-pointer material-icons text-scogobgsky text-font18 md:text-xl '
                            onClick={() =>
                                dispatch(
                                    copyToClipboard({
                                        data: `${validateStringValue(job_site_address_1)} ${validateStringValue(city_name)}, ${validateStringValue(state_name)}, ${job_fk_pincode_id}`,
                                    })
                                )
                            }
                        >
                            content_copy
                        </span>
                    </IconToolTip>
                </span>
            </span>
        </>
    )
}








