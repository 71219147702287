import { isScm, isCluster, isCustomer, isCustomerAdmin, isCustomerUser, isFe, isSp, isPsp, isIsp, isSuperVisor, isSpIsp, isCustomerGroup } from '../../utils/role';
import { useSelector } from 'react-redux';
import { getWalletPaymentStatus } from '../../actions/wallet';
import { closeModalAction, openCustomModal, openFullscreenModal, openTextModalAction } from '../../actions/modal';
import CancelPO from './Modals/CancelPO';
import { deletePoRequest } from '../../actions/payments';
import ManualPOInvoice from './Modals/ManualPOInvoice';
import PayPoTax from './Modals/PayPoTax';
import PayPo from './Modals/PayPO/index';
// import GenerateInvoice from './Modals/GenerateInvoice';
import GenerateInvoice from './Modals/GenerateInvoice';
import {
    isDeletePoAllowed,
    isViewPoAllowed,
    isViewWithdrawlsAllowed,
    isRefreshBankOpenBalanceAllowed,
    isApprovePaymentAllowed,
    isEditAllowedInAdditonalPayament,
} from '../../utils/access';
import { paymentsSidebarTab } from '../../layout/sidebar/index';
import PoApproval from '../Ticket/PoApproval';
import RenderUpdatePo from './Modals/RenderUpdatePo';

export const payoutsTab = {
    tabName: 'Payouts',
    tabID: 'payouts',
    path: 'payouts',
    tabIcon: '',
    iconSize: 'text-3xl',
};

export const withdrawlTab = {
    tabName: 'Withdrawls',
    tabID: 'withdraw',
    path: 'withdraw',
    tabIcon: '',
    iconSize: 'text-3xl',
};

export const PoTab = {
    tabName: 'PO',
    tabID: 'po',
    path: 'po',
    tabIcon: '',
    iconSize: 'text-3xl',
};

export const rewardsTab = {
    tabName: 'Rewards',
    tabID: 'rewards',
    path: 'rewards',
    tabIcon: '',
    iconSize: 'text-3xl',
};

export const openTicketPaymentsTab = {
    tabName: 'Open Ticket Payments',
    tabID: 'openTickets',
    path: 'openTickets',
    tabIcon: '',
    iconSize: 'text-3xl',
};

const refreshPayoutAction = {
    key: 'refresh_payout_action',
    menuName: 'Refresh',
    menuIcon: 'refresh',
    onClick: (transaction, dispatch) => {
        const { scogo_transaction_no } = transaction;
        return dispatch(getWalletPaymentStatus({ scogo_transaction_no }));
    },
};

export const paymentMode = {
    partner: 'PARTNER',
    customer: 'CUSTOMER',
    cluster: 'CLUSTER',
    psp: 'PSP',
    supervisor: 'SUPERVISOR',
    warehouseOwner: 'WAREHOUSE_OWNER',
};

export const additionalPaymentStatus = {
    pending: 'Pending',
    scheduled: 'Scheduled',
    success: 'Success',
    failure: 'Failure',
};

const cancelPO = {
    key: 'cancel_po',
    menuName: 'Cancel PO',
    menuIcon: 'highlight_off',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: (
                    <>
                        Cancel PO | <span className='text-scogobgsky'>{ticket.prNumber}</span>
                    </>
                ),
                modalWidth: '40rem',
                modalHeight: 'auto',
                modalComponent: <CancelPO ticket={ticket} isRefresh />,
            })
        );
    },
    isDisabled: (ticket) => {
        return { disabled: ticket.po.status === 'CANCELLED', message: 'PO is already cancelled' };
    },
};

const deletePO = {
    key: 'delete_po',
    menuName: 'Delete PO',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openTextModalAction({
                heading: `Delete PO | ${ticket.prNumber}`,
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deletePO',
                buttons: [
                    {
                        showLoading: true,
                        onClick: () => {
                            dispatch(deletePoRequest({ ticketIds: [ticket.ticketId], formName: 'deletePO', isRefresh: true, enduserId: ticket.enduserId }));
                        },
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (ticket) => {
        if (ticket.po.status === 'APPROVED') {
            return {
                disabled: true,
                message: 'Cannot delete approved PO',
            };
        }
        return {
            disabled: ticket.po.status === 'CANCELLED',
            message: 'Cannot delete cancelled PO',
        };
    },
};

const manualInvoice = {
    key: 'manual_po_invoice',
    menuName: 'Enter Manual Invoice',
    menuIcon: 'contact_page',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: (
                    <>
                        Enter Manual Invoice | <span className='text-scogobgsky'>{ticket.prNumber}</span>
                    </>
                ),
                modalWidth: '100rem',
                modalHeight: 'auto',
                modalComponent: <ManualPOInvoice ticket={ticket} isRefresh />,
            })
        );
    },
    isDisabled: (ticket) => {
        return { disabled: ticket.po.status === 'CANCELLED', message: 'Cancelled PO cannot be updated' };
    },
};

const updatePo = {
    key: 'update_po',
    menuIcon: 'update',
    conditionalRendering: (ticket) => {
        return { menuName: ticket?.po?.status === 'CANCELLED' ? 'Reopen PO' : 'Update PO' };
    },
    onClick: (ticket, dispatch) => {
        return dispatch(
            openFullscreenModal({
                modalComponent: <RenderUpdatePo ticket={ticket} ticketId={ticket.ticketId} isRefresh />,
            })
        );
    },
};

export const approvePo = {
    key: 'approve_po',
    menuName: 'Approve po',
    menuIcon: 'update',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        let modalComponent = <PoApproval ticketId={ticket.ticketId} />;
        dispatch(openFullscreenModal({ modalComponent }));
    },
};

const payPOTax = {
    key: 'pay_po_tax',
    menuName: 'Pay PO Tax',
    menuIcon: 'percent',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: (
                    <>
                        Pay PO Tax | <span className='text-scogobgsky'>{ticket.prNumber}</span>
                    </>
                ),
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: <PayPoTax ticketId={ticket.ticketId} vendorId={ticket?.po?.vendorId} isRefresh paymentDetails={ticket?.paymentDetails} enduserId={ticket.enduserId} />,
            })
        );
    },
    isDisabled: (ticket) => {
        if (ticket.po.status === 'CANCELLED') {
            return {
                disabled: true,
                message: 'PO is cancelled',
            };
        }
        return {
            disabled: ticket.po.status !== 'APPROVED',
            message: 'PO is not Approved',
        };
    },
};

const generateInvoice = {
    key: 'generate_invoice',
    menuName: 'Generate System Invoice',
    menuIcon: 'description',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: (
                    <>
                        Generate System Invoice | <span className='text-scogobgsky'>{ticket.prNumber}</span>
                    </>
                ),
                modalWidth: '85vw',
                modalHeight: 'auto',
                modalComponent: <GenerateInvoice ticket={ticket} isRefresh />,
            })
        );
    },
    isDisabled: (ticket) => {
        if (ticket.po.status !== 'APPROVED') {
            return {
                disabled: true,
                message: 'PO is not Approved',
            };
        }
        return {
            disabled: ticket.po?.poitems?.every((item) => item.isInvoiceGenerated),
            message: 'Invoices for all paid items have been generated',
        };
    },
};

const payPo = {
    key: 'pay_po',
    menuName: 'Pay PO',
    menuIcon: 'currency_rupee',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: `Pay PO | ${ticket.prNumber}`,
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <PayPo ticket={ticket} isRefresh />,
            })
        );
    },
    isDisabled: (ticket) => {
        return {
            disabled: ticket.po.status === 'CANCELLED' || ticket.po.status !== 'APPROVED',
            message: ticket.po.status === 'CANCELLED' ? 'PO is CANCELLED' : 'Only allowed to Pay on Approved PO',
        };
    },
};

export const getPaymentsMainTabs = (role, type, frontend_controller, manage_partner_payments) => {
    let tabs = [];

    if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type)) {
        tabs = [payoutsTab, openTicketPaymentsTab, rewardsTab, withdrawlTab, PoTab];
    } else if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
        if (manage_partner_payments === 1) {
            tabs = [withdrawlTab, PoTab];
        } else {
            tabs = [PoTab];
        }
    } else if (isPsp(role, type) || isSp(role, type) || isFe(role, type) || isIsp(role, type)) {
        tabs = [rewardsTab, withdrawlTab, PoTab];
    }

    tabs = tabs.filter((tab) => {
        if (isCustomerGroup(role, type) && tab.tabID === withdrawlTab.tabID && manage_partner_payments === 1) {
            return true;
        }
        if ([rewardsTab.tabID, withdrawlTab.tabID].includes(tab.tabID)) {
            return isViewWithdrawlsAllowed(frontend_controller);
        } else if (tab.tabID === PoTab.tabID) {
            return isViewPoAllowed(frontend_controller);
        }
        return true;
    });
    return tabs;
};

const getPaymentsModuleTitle = (role, type, frontend_controller) => {
    return typeof paymentsSidebarTab.name === 'function' ? paymentsSidebarTab.name(role, type, frontend_controller) : paymentsSidebarTab.name;
};

export const getpaymentAddNewButton = (tab) => {
    // if (tab === PoTab.tabID) return true;
    return false;
};

const getTabFromQueryString = (tabs, urlQueryString) => {
    const match = urlQueryString.match(/[\?&]tab=(\w+)/);
    let queryTab;
    if (match) queryTab = match[1];
    const matchingTab = tabs.find((tab) => tab.path === queryTab);
    if (matchingTab) return { matchingTab };
    return {};
};

const getPaymentsDefaultTab = (role, type, frontend_controller, { urlQueryString, manage_partner_payments }) => {
    const tabs = getPaymentsMainTabs(role, type, frontend_controller, manage_partner_payments);
    let tab = tabs[0];
    if (urlQueryString) {
        const { matchingTab } = getTabFromQueryString(tabs, urlQueryString);
        if (matchingTab) {
            tab = matchingTab;
        }
    }
    return tab?.tabID;
};

const getPOActions = (role, type, { frontend_controller, ticket, user }) => {
    let actions = [];
    if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type)) actions = [cancelPO, updatePo, payPo, payPOTax, approvePo, generateInvoice, manualInvoice];
    if (isScm(role, type)) actions = [cancelPO, updatePo, payPo, payPOTax, generateInvoice, manualInvoice, deletePO];
    if (isCluster(role, type)) actions = [cancelPO, updatePo, payPo, payPOTax, generateInvoice, manualInvoice, deletePO];
    if (isSuperVisor(role, type)) actions = [cancelPO, updatePo, payPo, payPOTax, generateInvoice, manualInvoice, deletePO];

    // pay po action filter

    actions = actions.filter((action) => {
        if (action.key === 'pay_po_tax' || action.key === 'pay_po') {
            // return isPayPoAllowed(frontend_controller);
            return false
        }
        if (action.key === 'delete_po') {
            return isDeletePoAllowed(frontend_controller);
        }
        if (action.key === updatePo.key) {
            return ticket?.po?.status !== 'CANCELLED' || ticket?.is_feasibility_eligible === 0;
        }
        if (action.key === 'approve_po' && ticket && user) {
            let displayPoApproval = false
            if (ticket.po && Array.isArray(ticket.po.approvers) && ticket.po.approvers.length > 0) {
                ticket.po.approvers.map(approver => {
                    if (Array.isArray(approver.users) && approver.users.length > 0) {
                        approver.users.map(userId => {
                            if (parseInt(userId) === parseInt(user.id) &&
                                !approver.closed && approver.authorization) {
                                displayPoApproval = true
                            }
                        })
                    }
                })
            }
            if (ticket.po && Array.isArray(ticket.po.approvals) && ticket.po.approvals.length > 0) {
                ticket.po.approvals.map(i => {
                    if (parseInt(i.userId) === parseInt(user.id)) {
                        displayPoApproval = false
                    }
                })
            }
            return displayPoApproval
        }
        return true;
    });
    return actions;
};

const getIsToShowAdditionalPaymentEditIcon = (frontend_controller) => {
    return isEditAllowedInAdditonalPayament(frontend_controller);
};

const isPayoutActionAllowed = (frontend_controller) => {
    return isApprovePaymentAllowed(frontend_controller);
};

export const GetPaymentsListLogic = (logic, { urlQueryString, tabID, ticket } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id,
        type = loggedUser.type,
        frontend_controller = loggedUser.frontend_controller;
    if (logic === 'defaultTab') return getPaymentsDefaultTab(role, type, frontend_controller, { urlQueryString, manage_partner_payments: loggedUser.manage_partner_payments });
    if (logic === 'tabs') return getPaymentsMainTabs(role, type, frontend_controller, loggedUser.manage_partner_payments);
    if (logic === 'getPoActions') return getPOActions(role, type, { frontend_controller, ticket, user: loggedUser });
    if (logic === 'isToShowBasePaymentColumnInTicketPaymentDetails') return !isCustomer(role, type) && !isCustomerAdmin(role, type) && !isCustomerUser(role, type);
    if (logic === 'isToShowPaymentBasePaymentInTicketDetails') return isScm(role, type) || isCluster(role, type) || isPsp(role, type) || isSpIsp(role, type) || isSp(role, type);
    if (logic === 'isToShowPaymentColumn')
        return (
            isScm(role, type) ||
            isCluster(role, type) ||
            isPsp(role, type) ||
            isSpIsp(role, type) ||
            isSp(role, type) ||
            isCustomer(role, type) ||
            isCustomerAdmin(role, type) ||
            isCustomerUser(role, type)
        );
    if (logic === 'isFeOrSps') return isFe(role, type) || isPsp(role, type) || isSpIsp(role, type) || isSp(role, type);
    if (logic === 'isToShowCustomer') return isScm(role, type);
    if (logic === 'isToShowExportCSVButton') return getIsToShowExportCSVButton(role, type, tabID);
    if (logic === 'isToShowDownloadZipButton') return getIsToShowDownloadZipButton(role, type, tabID);
    if (logic === 'isToShowAdditionalPaymentEditIcon') return getIsToShowAdditionalPaymentEditIcon(frontend_controller);
    if (logic === 'isToShowBalanceRefreshButton') return getIsToShowBalanceRefreshButton(role, type, { frontend_controller, tabID });
    if (logic === 'isToShowApproveOrTriggerAndEditButton') return isPayoutActionAllowed(frontend_controller);
    if (logic === 'paymentsModuleTitle') return getPaymentsModuleTitle(role, type);
};

const getIsToShowExportCSVButton = (role, type, tabID) => {
    const isUserHaveRoleSix = isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type);
    if (tabID === payoutsTab.tabID) return isScm(role, type) || isCluster(role, type);
    else if (tabID === PoTab.tabID) return isUserHaveRoleSix || isIsp(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type);
    return false;
};

const getIsToShowDownloadZipButton = (role, type, tabID) => {
    const isPoTab = tabID === PoTab.tabID;
    return (
        isPoTab &&
        (isScm(role, type) ||
            isCluster(role, type) ||
            isSuperVisor(role, type) ||
            isIsp(role, type) ||
            isCustomer(role, type) ||
            isCustomerAdmin(role, type) ||
            isCustomerUser(role, type))
    );
};

const getIsToShowBalanceRefreshButton = (role, type, { frontend_controller, tabID }) => {
    const isPayoutsTab = tabID === payoutsTab.tabID;
    return isPayoutsTab && isScm(role, type) && isRefreshBankOpenBalanceAllowed(frontend_controller);
};

export const GetRateCardObject = (logic, { ratecards } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const roleId = loggedUser?.role?.id;
    const type = loggedUser.type;
    if (logic === 'rateCard') return getRateCard(roleId, type, ratecards);
};

const getRateCard = (roleId, type, ratecards) => {
    if (isScm(roleId, type) || isCluster(roleId, type)) return ratecards?.['sp'];
    else return ratecards;
};

export const defaultRowsPerPage = 10;
export const defaultPage = 1;

export const requestRaised = {
    pendingForApproval: 1,
    approved: 2,
    rejected: 3,
    success: 4,
};

export const paymnetVia = {
    throughWallet: 1,
    throughBank: 0,
};

export const taxDeductionPercentage = ({ is_pancard_linked_with_aadhaar }) => {
    return is_pancard_linked_with_aadhaar === 1 ? 0.02 : 0.2;
};
