import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { remarkType } from './editPayoutContainer';
import IconToolTip from '../../../../common/IconToolTip';
import OverflowTip from '../../../../common/OverflowTip';
import { Actions } from './Actions';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isValidJSON, validateNumberValue, } from '../../../../utils/common';
import { requestRaised, taxDeductionPercentage } from '../../PaymentController';
import { isCluster, isCustomerGroup, isPsp, isScm, isSuperVisor } from '../../../../utils/role';
import FormTable from '../../../../common/Form/Table';
import DatePicker from '../../../../common/Form/DatePicker';
import Select from '../../../../common/Form/Select';
import Upload from '../../../../common/Form/FileUpload';
import TextArea from '../../../../common/Form/TextArea';
import Input from '../../../../common/Form/Input';
import { useFieldArray, useFormContext } from 'react-hook-form';
import UserPopup from '../../../../common/Popups/User';
import { hasAdditionalPriceAccess } from '../../../../utils/access';
import { getEligibleSPsList } from '../../../../actions/sp';
import { useDispatch } from 'react-redux';
import ButtonScogoOrange from '../../../../common/Buttons/ButtonScogoOrange';
import ButtonScogoClosed from '../../../../common/Buttons/ButtonScogoClosed';
import { getTicketDetails } from '../../../../actions/tickets';
import { getTicketOwner } from '../../../Ticket/TicketController';


const RenderTaxDeductionPercanetage = ({ is_pancard_linked_with_aadhaar, employee_type, id }) => {
    if (employee_type === 'EMP' || !id) return <></>;
    let text = is_pancard_linked_with_aadhaar === 1 ? '2% TDS' : '20% TDS';
    return <span>{text}</span>
}

export const EditPayoutTable = (props) => {
    const { onBasePaymentChange, tableHeaderName, isEditingRow, showAdditionalAmountApprove, setIsEditingRow, ticketId, ticketBasePayment, arrayName, tableLoading } = props;
    const dispatch = useDispatch();
    const { editPayoutLoading, isSpDropdownListLoading } = useSelector((state) => state.loading);
    let { ticketDetails } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector((state) => state.auth);
    const { eligibleSPs } = useSelector((state) => state.sp);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const { watch, setValue, control, } = useFormContext();
    const { fields, append, remove, update } = useFieldArray({ name: 'custom_users', control });
    const watchEditPayout = watch(arrayName);
    const [categoryOptions, setCategoryOptions] = useState(remarkType);
    const [selectedCategories, setSelectedCategories] = useState({});

    const getCategoryList = useCallback((list) => {
        if (isCustomerGroup(role, type)) {
            return list.filter(({ value }) => ['TRAVEL', 'MATERIAL', 'COURIER', 'SERVICE'].includes(value))
        }
        return list.filter((item) => {
            if (item.value === 'TRAVEL') {
                return isScm(role, type) || isCluster(role, type) || (ticketDetails?.accepted_sp_id === loggedUser.id && ticketDetails?.is_live_tracking_enabled === 1) || isSuperVisor(role, type);
            } else if ("MATERIAL" === item.value) {
                return (ticketDetails?.accepted_sp_id === loggedUser.id) || isScm(role, type) || isSuperVisor(role, type);
            } else if ("COURIER" === item.value) {
                return (ticketDetails?.accepted_sp_id === loggedUser.id) || (ticketDetails?.warehouse_owner === loggedUser.id) || isScm(role, type) || isSuperVisor(role, type);
            } else if (item.value === 'INCENTIVE') {
                return isCluster(role, type) || isScm(role, type) || isPsp(role, type) || isSuperVisor(role, type);
            } else if (item.value === 'SERVICE') {
                return isCluster(role, type) || isScm(role, type) || isSuperVisor(role, type);
            } else if (item.value === 'GST') {
                return isScm(role, type);
            }
            return false;
        })
    }, [role, type, loggedUser.id, ticketDetails?.accepted_sp_id, ticketDetails?.is_live_tracking_enabled, ticketDetails?.warehouse_owner]);
    const eligibleSPsDropdown = useMemo(() => eligibleSPs?.map(sp => ({
        id: sp.id, key: sp.id, value: sp.id, label: `${sp.sp_name ? `${sp.sp_name}, ` : ''}${sp.name}, ${sp.mobile}`, name: `${sp.name}`, is_pancard_linked_with_aadhaar: sp.is_pancard_linked_with_aadhaar, employee_type: sp.employee_type
    })), [eligibleSPs]);

    useEffect(() => {
        if (!isSpDropdownListLoading && !eligibleSPsDropdown) {
            dispatch(getEligibleSPsList({ ticketId, }));
        }
    }, [dispatch, eligibleSPsDropdown, isSpDropdownListLoading, ticketId])

    const prefilledCustomUsers = useMemo(() => {
        const res = []
        if (ticketDetails?.additional_user_payments && isValidJSON(ticketDetails?.additional_user_payments)) {
            const additional_payments = JSON.parse(ticketDetails.additional_user_payments);
            const encountered = new Set();
            res.push(...additional_payments
                .filter(p => {
                    if (encountered.has(p.id)) return false;
                    encountered.add(p.id);
                    return p.id !== ticketDetails.accepted_sp_id;
                })
                .filter(p => eligibleSPsDropdown?.find(sp => sp.id === p.id))
                .map(p => eligibleSPsDropdown?.find(sp => sp.id === p.id))
            )
        }
        return res;
    }, [ticketDetails?.accepted_sp_id, ticketDetails?.additional_user_payments, eligibleSPsDropdown]);

    const customUserOptions = useMemo(() => {
        if (!eligibleSPsDropdown) return []
        return eligibleSPsDropdown.filter(sp => {
            return prefilledCustomUsers.every(p => p.id !== sp.id) && fields.every(field => field.selection.id !== sp.id)
        })
    }, [prefilledCustomUsers, eligibleSPsDropdown, fields]);

    useLayoutEffect(() => {
        if (remarkType) {
            let list = getCategoryList(remarkType);
            setCategoryOptions(list);
        }
    }, [getCategoryList]);

    const getHeaderName = (value, id, is_pancard_linked_with_aadhaar) => {
        let text_on_hover = '';
        if (!id) {
            text_on_hover = value;
        } else if (is_pancard_linked_with_aadhaar) {
            text_on_hover = `${value} : Aadhaar is linked with PAN`
        } else {
            text_on_hover = `${value} : Aadhaar is not linked with PAN`
        }

        return <IconToolTip title={text_on_hover}>
            <span className='text-font11 font-medium'>{value}</span>
        </IconToolTip>
    };

    const tableBodyRef = useRef();
    const prevNumRowsRef = useRef();
    const numRows = watchEditPayout?.length;
    useEffect(() => {
        if (numRows > prevNumRowsRef.current) {
            tableBodyRef?.current?.scroll({ top: tableBodyRef.current.scrollHeight, behavior: 'smooth' });
        }
        prevNumRowsRef.current = numRows;
    }, [numRows]);

    const getColumnDataWithOverflowTip = (value) => {
        if (value) {
            return (
                <div className='w-4/5'>
                    <OverflowTip someLongText={value} />
                </div>
            );
        }
        return <></>;
    };

    const getPaymentRequestedBy = (payment) => {
        if (payment?.createdBy) {
            return (
                <>
                    <OverflowTip
                        someLongText={
                            <small>
                                Requested by <br></br>
                                <b>{`${payment?.createdBy?.first_name} ${payment.createdBy.last_name}`}</b>
                            </small>
                        }
                    />
                </>
            );
        }
        return <></>;
    };

    const isUserEditing = (idx) => isEditingRow.includes(idx);

    const isToShowInputFieldOnApproveAndRejectActions = (payment, idx) => payment.type === 'Additional Payment' && payment.status === 'isPendingProcessed' && isUserEditing(idx);
    const isScmPspOrClusterSelectedIncentive = (id) => (isScm(role, type) || isCluster(role, type) || isPsp(role, type)) && selectedCategories[id] === 'INCENTIVE';

    const onCategoryChange = (referenceId) => {
        let payments_list = watch(arrayName);
        const findIndex = payments_list && payments_list.findIndex((payment) => payment.referenceId === referenceId);
        if (findIndex > -1 && payments_list[findIndex]) {
            payments_list[findIndex].customer_details = '';
            payments_list[findIndex].partner_details = '';
            payments_list[findIndex].cluster_details = '';
            payments_list[findIndex].psp_details = '';
            payments_list[findIndex].warehouse_details = '';
            payments_list[findIndex].custom_amounts = {};
            setValue(arrayName, payments_list);
        }
    }

    const paymentRatioMap = useMemo(() => {
        if (ticketDetails) {
            const tempArr = [];
            const { accepted_s_p_company_detail, cl_details, supervisor_details, warehouse_owner_details, additional_user_payments } = ticketDetails;
            if (accepted_s_p_company_detail) {
                tempArr.push([accepted_s_p_company_detail.fk_user_id, accepted_s_p_company_detail.payment_ratio])
            }
            if (cl_details) {
                tempArr.push([cl_details.id, cl_details.payment_ratio])
            }
            if (supervisor_details) {
                tempArr.push([supervisor_details.id, supervisor_details.payment_ratio])
            }
            if (warehouse_owner_details) {
                tempArr.push([warehouse_owner_details.id, warehouse_owner_details.payment_ratio])
            }
            if (additional_user_payments && Array.isArray(JSON.parse(additional_user_payments))) {
                JSON.parse(additional_user_payments).forEach((user) => {
                    tempArr.push([user.id, user.payment_ratio])
                })
            }
            return new Map(tempArr);
        }
    }, [ticketDetails]);

    const isPaymentRationZeroAndSelectedCategoryIsService = (id, userId) => paymentRatioMap.get(userId) === parseInt(0) && selectedCategories[id] === 'SERVICE';

    let defalutSchema = [
        {
            head: {
                thClassName: `w-48 truncate mb-2 pl-3`,
                render: (headName) => {
                    return <span className='text-font11 font-medium'>{headName}</span>
                },
                headName: `Type`,
            },
            body: {
                bodyClassName: `w-48 truncate`,
                render: (data) => (
                    <div className='pl-6'>
                        {getColumnDataWithOverflowTip(data.type)}
                        {getPaymentRequestedBy(data)}
                    </div>
                ),
            },
        },
        {
            head: {
                thClassName: `w-32 truncate mb-2`,
                render: (headName) => {
                    return <span className='text-font11 font-medium'>{headName}</span>
                },
                headName: `Scheduled On`,
            },
            body: {
                bodyClassName: `w-32 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isToHidedate && data.type === 'Base Payment') return <></>
                    if ((rowIndex === 0 && ticketBasePayment?.is_request_raised !== requestRaised.success) || data.isEditable || isUserEditing(rowIndex)) {
                        return (
                            <DatePicker
                                name={`${arrayName}[${rowIndex}].scheduled_on`}
                                placeholder={'Scheduled On'}
                                onChangeHandler={(e) => onBasePaymentChange(e, rowIndex)}
                                minDate={new Date()}
                                padding={'py-2'}
                                isClearable={false}
                            />
                        );
                    }
                    return moment(data.scheduled_on).format('DD-MM-YYYY');
                },
            },
        },
        {
            head: {
                thClassName: `w-36 truncate mb-2`,
                headName: `Category`,
                render: (headName) => {
                    return <span className='text-font11 font-medium'>{headName}</span>
                },
                required: true,
            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable) {
                        return (
                            <Select
                                name={`${arrayName}[${rowIndex}].category`}
                                required
                                options={categoryOptions}
                                onChange={() => onCategoryChange(data.referenceId)}
                            />
                        );
                    } else {
                        return getColumnDataWithOverflowTip(data?.category?.label);
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-44 truncate mb-2`,
                headName: `Remark`,
                render: (headName) => {
                    return <span className='text-font11 font-medium'>{headName}</span>
                },
                required: true,
            },
            body: {
                bodyClassName: `w-44 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable) {
                        return <TextArea type='text' name={`${arrayName}[${rowIndex}].remark`} placeholder='Remark' />;
                    } else {
                        return getColumnDataWithOverflowTip(data.remark);
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-40 truncate mb-2`,
                headName: `Proof`,
                render: (headName) => {
                    return <span className='text-font11 font-medium'>{headName}</span>
                },
            },
            body: {
                bodyClassName: `w-40 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable) {
                        return <Upload name={`${arrayName}[${rowIndex}].proof`} placeholder='Proof' />;
                    } else if (data.proof) {
                        return (
                            <a href={data.proof} target='_blank' className='text-scogoorange' rel='noreferrer' download>
                                Proof
                            </a>
                        );
                    }
                    return;
                },
            },
        },
        {
            head: {
                thClassName: `w-36 mb-2 `,
                render: () => {
                    return (
                        <UserPopup
                            userId={tableHeaderName?.customer?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span>
                                {getHeaderName(tableHeaderName?.customer?.name)} <br />
                                (Customer)
                            </span>
                        </UserPopup>

                    )
                },
                headName: 'Customer',
                showTotal: true,
                key: 'customer'
            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].customer_details`} placeholder='Amount' readOnly={isScmPspOrClusterSelectedIncentive(data.referenceId)} />
                    } else {
                        return <div className='truncate w-4/5'> ₹ {data.customer_details}</div>;
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-36 truncate mb-2`,
                render: () => {
                    let text = tableHeaderName?.partner?.name || ''
                    return (
                        <UserPopup
                            userId={tableHeaderName?.partner?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span >
                                {getHeaderName(text, tableHeaderName?.partner?.id, tableHeaderName?.partner?.is_pancard_linked_with_aadhaar === 1)} <br />
                                (Partner) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={ticketDetails?.accepted_s_p_company_detail?.is_pancard_linked_with_aadhaar} employee_type={ticketDetails?.accepted_s_p_company_detail?.employee_type} id={ticketDetails?.accepted_sp_id} />
                            </span>
                        </UserPopup>
                    )
                },
                headName: `Partner`,
                showTotal: true,
                key: 'partner'

            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable || isUserEditing(rowIndex) || (isUserEditing(rowIndex) && data.partner_details !== 0) || isToShowInputFieldOnApproveAndRejectActions(data, rowIndex)) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].partner_details`} placeholder='Amount' readOnly={isScmPspOrClusterSelectedIncentive(data.referenceId) || isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, data.partner_id)} />;
                    } else {
                        const info = rowIndex === 0 && data.partner_details > 0 && (
                            <>
                                <br />
                                (Service)
                            </>
                        );
                        return (
                            <div className='truncate w-4/5'>
                                {' '}
                                ₹ {data.partner_details}
                                {info}{' '}
                            </div>
                        );
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-36 mb-2`,
                render: () => {
                    let text = tableHeaderName?.cluster?.name || '';
                    return (
                        <UserPopup
                            userId={tableHeaderName?.cluster?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span>
                                {getHeaderName(text, tableHeaderName?.cluster?.id, tableHeaderName?.cluster?.is_pancard_linked_with_aadhaar === 1)} <br />
                                (Cluster) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={ticketDetails?.cl_details?.is_pancard_linked_with_aadhaar} employee_type={ticketDetails?.cl_details?.employee_type} id={ticketDetails?.cl_details?.id} />
                            </span>
                        </UserPopup>

                    )
                },
                headName: `Cluster`,
                showTotal: true,
                key: 'cluster'

            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable || isUserEditing(rowIndex) || (isUserEditing(rowIndex) && data.cluster_details !== 0) || !data.status) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].cluster_details`} placeholder='Amount' readOnly={isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, data.cluster_id)} />;
                    } else {
                        const info = rowIndex === 0 && data.cluster_details > 0 && (
                            <>
                                <br />
                                (Incentive)
                            </>
                        );
                        return (
                            <div className='truncate w-4/5'>
                                ₹ {data.cluster_details}
                                {info}
                            </div>
                        );
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-36 mb-2`,
                render: () => {
                    let text = tableHeaderName?.psp?.name || ''
                    return (

                        <UserPopup
                            userId={tableHeaderName?.psp?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span>
                                {getHeaderName(text, tableHeaderName?.psp?.id, tableHeaderName?.psp?.is_pancard_linked_with_aadhaar === 1)} <br />
                                (PSP) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={ticketDetails?.psp_details?.is_pancard_linked_with_aadhaar} employee_type={ticketDetails?.psp_details?.employee_type} id={ticketDetails?.psp_details?.id} />
                            </span>
                        </UserPopup>
                    )
                },
                headName: `PSP`,
                showTotal: true,
                key: 'psp'
            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable || isUserEditing(rowIndex) || (isUserEditing(rowIndex) && data.psp_details !== 0)) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].psp_details`} placeholder='Amount' readOnly={isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, data.psp_id)} />;
                    } else {
                        const info = rowIndex === 0 && data.psp_details > 0 && (
                            <>
                                <br />
                                (Incentive)
                            </>
                        );
                        return (
                            <div className='truncate w-4/5'>
                                ₹ {data.psp_details}
                                {info}
                            </div>
                        );
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-36 mb-2`,
                render: () => {
                    let text = tableHeaderName?.supervisor?.name || ''
                    return (
                        <UserPopup
                            userId={tableHeaderName?.supervisor?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span>
                                {getHeaderName(text, tableHeaderName?.supervisor?.id, tableHeaderName?.supervisor?.is_pancard_linked_with_aadhaar === 1)} <br />
                                (Supervisor) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={ticketDetails?.supervisor_details?.is_pancard_linked_with_aadhaar} employee_type={ticketDetails?.supervisor_details?.employee_type} id={ticketDetails?.supervisor_details?.id} />
                            </span>
                        </UserPopup>
                    )
                },
                headName: `Supervisor`,
                showTotal: true,
                key: 'supervisor'
            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable || isUserEditing(rowIndex) || (isUserEditing(rowIndex) && data.psp_details !== 0)) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].supervisor_details`} placeholder='Amount' readOnly={isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, data.supervisor_id)} />;
                    } else {
                        const info = rowIndex === 0 && data.supervisor_details > 0 && (
                            <>
                                <br />
                                (Incentive)
                            </>
                        );
                        return (
                            <div className='truncate w-4/5'>
                                ₹ {data.supervisor_details}
                                {info}
                            </div>
                        );
                    }
                },
            },
        },
        {
            head: {
                thClassName: `w-36 mb-2`,
                render: () => {
                    let text = tableHeaderName?.warehouse_owner?.name || ''
                    return (
                        <UserPopup
                            userId={tableHeaderName?.warehouse_owner?.id}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <span>
                                {getHeaderName(text, tableHeaderName?.warehouse_owner?.id, tableHeaderName?.warehouse_owner?.is_pancard_linked_with_aadhaar === 1)} <br />
                                (Warehouse Owner) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={ticketDetails?.warehouse_owner_details?.is_pancard_linked_with_aadhaar} employee_type={ticketDetails?.warehouse_owner_details?.employee_type} id={ticketDetails?.warehouse_owner_details?.id} />
                            </span>

                        </UserPopup>
                    )
                },
                headName: `Warehouse Owner`,
                showTotal: true,
                key: 'warehouse_owner'
            },
            body: {
                bodyClassName: `w-36 truncate`,
                render: (data, { rowIndex }) => {
                    if (data.isEditable || isUserEditing(rowIndex) || (isUserEditing(rowIndex) && data.warehouse_details !== 0)) {
                        return <Input type='number' name={`${arrayName}[${rowIndex}].warehouse_details`} placeholder='Amount' readOnly={isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, data.warehouse_owner)} />;
                    } else {
                        const info = rowIndex === 0 && data.warehouse_details > 0 && (
                            <>
                                <br />
                                (Incentive)
                            </>
                        );
                        return (
                            <div className='truncate w-4/5'>
                                ₹ {data.warehouse_details}
                                {info}
                            </div>
                        );
                    }
                },
            },
        }, ...prefilledCustomUsers.map((u, uIdx) => {
            return {
                head: {
                    thClassName: `w-36 truncate mb-2`,
                    render: () => {
                        return (
                            <UserPopup
                                userId={u.id}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <span >
                                    {getHeaderName(u.name, u.id, u.is_pancard_linked_with_aadhaar === 1)} <br />
                                    (Partner) <RenderTaxDeductionPercanetage is_pancard_linked_with_aadhaar={u.is_pancard_linked_with_aadhaar} employee_type={u.employee_type} id={u.id} />
                                </span>
                            </UserPopup>
                        )
                    },
                    headName: `${u.id}`,
                    showTotal: true,
                    key: `${u.id}`,
                    isCustomUser: true

                },
                body: {
                    bodyClassName: `w-36 truncate`,
                    render: (data, { rowIndex }) => {
                        if (data.isEditable || isUserEditing(rowIndex) || isToShowInputFieldOnApproveAndRejectActions(data, rowIndex)) {
                            return <Input type='number' name={`${arrayName}[${rowIndex}].custom_amounts[${u.id}].amount`} placeholder='Amount' readOnly={isScmPspOrClusterSelectedIncentive(data.referenceId) || isPaymentRationZeroAndSelectedCategoryIsService(data.referenceId, u.id)} />;
                        } else if (data.custom_amounts?.[u.id]?.amount) {
                            return (
                                <div className='truncate w-4/5'>
                                    {' '}
                                    ₹ {data.custom_amounts?.[u.id].amount}
                                </div>
                            );
                        } else return <></>
                    },
                },
            }
        }), ...fields.map((field, fIdx) => {
            return {
                head: {
                    thClassName: `w-60 truncate mb-2 mr-4`,
                    render: () => {
                        return (<>
                            <Select
                                className=''
                                name={`custom_users[${fIdx}].selection`}
                                required
                                options={customUserOptions}
                                isClearable={false}
                                isLoading={isSpDropdownListLoading}
                                onChange={(value) => {
                                    update(fIdx, { ...field, selection: value })
                                }}
                            />
                            <ButtonScogoClosed
                                textOrComponent={'Delete'}
                                buttonClass='text-xs float-right'
                                disabledClass='text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd text-xs float-right'
                                buttonPadding='px-2 py-1'
                                onClick={() => remove(fIdx)}
                            />
                        </>)
                    },
                    headName: `${field.selection.id}`,
                    showTotal: true,
                    key: `${field.selection.id}`,
                    isCustomUser: true

                },
                body: {
                    bodyClassName: `w-60 truncate`,
                    render: (data, { rowIndex }) => {
                        if (data.isToHidedate && data.type === 'Base Payment') return <></>
                        if (data.isEditable || isUserEditing(rowIndex) || isToShowInputFieldOnApproveAndRejectActions(data, rowIndex)) {
                            return <Input type='number' name={`${arrayName}[${rowIndex}].custom_amounts[${field.selection.id}].amount`} placeholder='Amount' readOnly={isScmPspOrClusterSelectedIncentive(data.referenceId)} />;
                        } else if (data.custom_amounts?.[field.selection.id]?.amount) {
                            return (
                                <div className='truncate w-4/5'>
                                    {' '}
                                    ₹ {data.custom_amounts?.[field.selection.id].amount}
                                </div>
                            );
                        } else return <></>
                    },
                },
            }
        }),
        {
            head: {
                thClassName: `w-60 truncate`,
                headName: `Status`,
                render: (headName) => {
                    return <span className='text-font11 font-medium flex gap-10'>{headName}
                        {isScm(loggedUser.role.id, loggedUser.type) && <ButtonScogoOrange textOrComponent='+ User ' onClick={() => {
                            append({ selection: customUserOptions[0] })
                            const rows = watch(arrayName);
                            if (rows.every(row => !row.isDeletable)) {
                                formTableRef.current?.appendRow?.();
                            }
                        }}
                            buttonClass={`px-4`} />}
                    </span>
                },
            },
            body: {
                bodyClassName: `w-56 truncate`,
                render: (payment, { rowIndex, remove: removeRow }) => {
                    return (
                        <Actions
                            payment={payment}
                            idx={rowIndex}
                            onDeleteClick={() => removeRow(rowIndex)}
                            isUserEditing={isUserEditing}
                            showAdditionalAmountApprove={showAdditionalAmountApprove}
                            setIsEditingRow={setIsEditingRow}
                            ticketId={ticketId}
                            arrayName={arrayName}
                            eligibleSPsDropdown={eligibleSPsDropdown}
                            custom_user_ids={[...prefilledCustomUsers.map(cu => cu.id), ...fields.map(f => f.selection.id)]}
                            onSuccess={() => {
                                setValue('custom_users', [])
                                dispatch(getTicketDetails({ ticketId: ticketId, payment_transaction: true }));
                            }}
                        />
                    );
                },
            },
        },
    ];

    const isTicketOwnerIsCustomer = ticketDetails && isCustomerGroup(role, type) && getTicketOwner({ ticket_owned_by: ticketDetails.ticket_owned_by, assign_to_scogo: ticketDetails.assign_to_scogo }) === 'Customer';

    const getFilteredTableSchema = () => {
        let schema = defalutSchema.filter(({ head }) => {
            if (head.headName === 'Cluster') return ticketDetails?.cluster_id > 0 && (isScm(role, type) || ticketDetails?.cluster_id === loggedUser.id);
            else if (head.headName === 'PSP') return ticketDetails?.psp_id > 0 && (isScm(role, type) || isCluster(role, type) || ticketDetails?.psp_id === loggedUser.id);
            else if (head.headName === 'Supervisor') return ticketDetails?.supervisor_id > 0 && (isScm(role, type) || ticketDetails?.supervisor_id === loggedUser.id);
            else if (head.headName === 'Warehouse Owner')
                return ticketDetails?.is_rma_raised === 1 && ticketDetails?.warehouse_owner > 0 && (isScm(role, type) || ticketDetails?.warehouse_owner === loggedUser.id);
            else if (head.headName === 'Customer') return isScm(role, type) || isCustomerGroup(role, type);
            else if (head.headName === 'Partner') return !isCustomerGroup(role, type) || isTicketOwnerIsCustomer;
            else if (head.isCustomUser) return isScm(role, type) || isCluster(role, type) || isPsp(role, type) || isTicketOwnerIsCustomer
            return true;
        });
        return { table: schema };
    };

    const rowGenerator = useCallback(() => ({
        isEditable: true,
        isDeletable: true,
        type: 'Additional Payment',
        status: 'isScheduled',
        scheduled_on: isCustomerGroup(role, type) ? new Date() : getDateDaysInFuture(7),
        referenceId: uuidv4(),
    }), [role, type]);

    const tableSchema = getFilteredTableSchema();

    const formTableRef = useRef();
    return <div style={{ flexFlow: 'column' }} className='px-6 flex h-full'>
        <div className='flex-auto overflow-auto'>
            <FormTable ref={formTableRef} buttonDivClass='px-6 pt-8' formTableClass='shadow-lg tableContainerEditPayout rounded-lg border overflow-y-hidden' scrollToBottomOnAddRow
                isLoading={editPayoutLoading || tableLoading} schema={tableSchema} name={arrayName} allowAdd={true} rowGenerator={rowGenerator} />
        </div>
        <RenderTotalAmount tableSchema={tableSchema} arrayName={arrayName} setSelectedCategories={setSelectedCategories} selectedCategories={selectedCategories} eligibleSPsDropdown={eligibleSPsDropdown} />
    </div>

};

const getDateDaysInFuture = (numDays) => {
    let date = new Date();
    date.setDate(date.getDate() + numDays);
    return date;
};

const RenderTotalAmount = ({ tableSchema, arrayName, selectedCategories, setSelectedCategories, eligibleSPsDropdown }) => {
    const { watch, setValue } = useFormContext()
    let { ticketDetails } = useSelector((state) => state.tickets);
    const [totalValues, setTotalValues] = useState({});

    useEffect(() => {
        const { unsubscribe } = watch((values) => {
            let payments = values?.[arrayName] || [];
            payments = payments.filter(({ type }) => type !== 'WITHDRAW');
            if (Array.isArray(payments)) {
                payments.forEach((payment) => {
                    if (payment.category && payment.category.value !== selectedCategories[payment.referenceId]) {
                        setSelectedCategories((prevState) => ({ ...prevState, [payment.referenceId]: payment.category.value }));
                    }
                });

                const customer = payments.reduce((sum, data) => {
                    let isRejected = data.status === 'isRejected';
                    let customer_details = validateNumberValue(data.customer_details);
                    sum += customer_details !== 0 && !isRejected ? customer_details : 0;
                    return sum;
                }, 0);

                const partner = payments.reduce((sum, data) => {
                    const is_pancard_linked_with_aadhaar = ticketDetails?.accepted_s_p_company_detail?.is_pancard_linked_with_aadhaar;
                    let isRejected = data.status === 'isRejected';
                    let partner_details = validateNumberValue(data.partner_details);
                    if (
                        ['isScheduled', 'isPendingProcessed'].includes(data.status) &&
                        data?.category?.value === 'SERVICE' &&
                        ticketDetails?.accepted_s_p_company_detail?.employee_type !== 'EMP'
                    ) {
                        partner_details = partner_details - partner_details * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
                    }
                    sum += partner_details !== 0 && !isRejected ? partner_details : 0;
                    return sum;
                }, 0);

                const cluster = payments.reduce((sum, data) => {
                    const is_pancard_linked_with_aadhaar = ticketDetails?.cl_details?.is_pancard_linked_with_aadhaar;
                    let isRejected = data.status === 'isRejected';
                    let cluster_details = validateNumberValue(data.cluster_details);
                    if (
                        ['isScheduled', 'isPendingProcessed'].includes(data.status) &&
                        data?.category?.value === 'SERVICE' &&
                        ticketDetails?.accepted_s_p_company_detail?.employee_type !== 'EMP'
                    ) {
                        cluster_details = cluster_details - cluster_details * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
                    }
                    sum += cluster_details !== 0 && !isRejected ? cluster_details : 0;
                    return sum;
                }, 0);

                const psp = payments.reduce((sum, data) => {
                    const is_pancard_linked_with_aadhaar = ticketDetails?.psp_details?.is_pancard_linked_with_aadhaar;
                    let isRejected = data.status === 'isRejected';
                    let psp_details = validateNumberValue(data.psp_details);
                    if (
                        ['isScheduled', 'isPendingProcessed'].includes(data.status) &&
                        data?.category?.value === 'SERVICE' &&
                        ticketDetails?.accepted_s_p_company_detail?.employee_type !== 'EMP'
                    ) {
                        psp_details = psp_details - psp_details * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
                    }
                    sum += psp_details !== 0 && !isRejected ? psp_details : 0;
                    return sum;
                }, 0);

                const supervisor = payments.reduce((sum, data) => {
                    const is_pancard_linked_with_aadhaar = ticketDetails?.supervisor_details?.is_pancard_linked_with_aadhaar;
                    let isRejected = data.status === 'isRejected';
                    let supervisor_details = validateNumberValue(data.supervisor_details);
                    if (
                        ['isScheduled', 'isPendingProcessed'].includes(data.status) &&
                        data?.category?.value === 'SERVICE' &&
                        ticketDetails?.accepted_s_p_company_detail?.employee_type !== 'EMP'
                    ) {
                        supervisor_details = supervisor_details - supervisor_details * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
                    }
                    sum += supervisor_details !== 0 && !isRejected ? supervisor_details : 0;
                    return sum;
                }, 0);

                const warehouse_owner = payments.reduce((sum, data) => {
                    const is_pancard_linked_with_aadhaar = ticketDetails?.warehouse_owner_details?.is_pancard_linked_with_aadhaar;
                    let isRejected = data.status === 'isRejected';
                    let warehouse_details = validateNumberValue(data.warehouse_details);
                    if (
                        ['isScheduled', 'isPendingProcessed'].includes(data.status) &&
                        data?.category?.value === 'SERVICE' &&
                        ticketDetails?.accepted_s_p_company_detail?.employee_type !== 'EMP'
                    ) {
                        warehouse_details = warehouse_details - warehouse_details * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
                    }
                    sum += warehouse_details !== 0 && !isRejected ? warehouse_details : 0;
                    return sum;
                }, 0);

                let tv = { partner, customer, cluster, psp, supervisor, warehouse_owner }
                const custom_sums = payments.reduce((sum, payment) => {
                    let isRejected = payment.status === 'isRejected';
                    if (payment.custom_amounts) {
                        Object.entries(payment.custom_amounts).forEach(([key, value]) => {
                            const cu = eligibleSPsDropdown?.find(u => `${u.id}` === key)
                            let tds = 0;
                            if (
                                ['isScheduled', 'isPendingProcessed'].includes(payment.status) &&
                                payment?.category?.value === 'SERVICE' &&
                                cu?.employee_type !== 'EMP'
                            ) {
                                tds = validateNumberValue(value.amount) * taxDeductionPercentage({ is_pancard_linked_with_aadhaar: cu?.is_pancard_linked_with_aadhaar });
                            }
                            const x = validateNumberValue(value.amount) - tds
                            if (sum[key] >= 0) {
                                sum[key] += (!isRejected ? x : 0);
                            } else {
                                sum[key] = (!isRejected ? x : 0);
                            }
                        })
                    }
                    return sum;
                }, {})
                tv = { ...tv, ...custom_sums }
                setTotalValues(tv)
            }
        });
        return () => unsubscribe();
    }, [watch, setValue, arrayName, ticketDetails, eligibleSPsDropdown]);

    return <div className='flex flex-initial w-full py-2 border-t justify-between'>
        {tableSchema.table.map(({ head }, { rowIndex }) => {
            let elem = <></>;
            if (head.showTotal) {
                const total = validateNumberValue(totalValues?.[head.key]);
                elem = (
                    <IconToolTip title={`After TDS deduction total price: ${total.toFixed(2)}`}>
                        <span>{total.toFixed(2)}</span>
                    </IconToolTip>
                );
            }
            return (
                <div key={head.headName} className={`${head.thClassName} font-bold text-xl`}>
                    {elem}
                </div>
            );
        })}
    </div>
};




