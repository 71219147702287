import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import MiniPopup from '../../../common/MiniPopup';
import NoResultFound from '../../../common/NoResultFound';
import { ApprovePayment } from './PayoutsModal/ApprovePayment';
import { EditPayoutTable } from './EditPayout/EditPayoutTable';
import { getGroupByAdditonalData, getBasePaymentData } from './EditPayout/editPayoutContainer';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import { cleanupTicketDetails, cleanupTicketPaymentDetails, approveTicketSignOff, getTicketDetails, getTicketPayments, viewTicketById, getBOQList } from '../../../actions/tickets';
import { isDateValid } from '../../../utils/utils';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';
import { isApprovePaymentAllowed, isDirectBankTransferAllowed } from '../../../utils/access';
import { approveWalletPayment } from '../../../actions/wallet';
import { actualValueForDropdown } from '../../../utils/common';
import Form from '../../../common/Form';
import Select from '../../../common/Form/Select';
import { useForm } from 'react-hook-form';
import TextArea from '../../../common/Form/TextArea';
import { useNavigate } from 'react-router-dom';
import { sendToastMessage } from '../../../actions/toast';
import { FeRating } from './FeRating';
import StatusLabel from '../reusableComponents/StatusLabel';
import RenderAddConsumables from './EditPayout/AddConsumables';
import TicketBankTransferPayment from './EditPayout/TicketBankTransferPayment';
import { isCustomerGroup, isScm } from '../../../utils/role';

const siteStateOptions = [
    { value: 'COMPLETED', label: 'Completed', key: 1 },
    { value: 'PARTIALLY_CLOSED', label: 'Partially Closed', key: 2 },
    { value: 'VISIT', label: 'Visit', key: 3 },
];
const formName = 'editPayout';
const arrayName = 'editPayout';

const EditPayout = (props) => {
    let { ticketId, showAdditionalAmountApprove, isServiceParnter = false, showApproveSignOff = false, onCancel = () => navigate(-1) } = props;
    const dispatch = useDispatch();
    const formDivRef = useRef(null);
    const approveRef = useRef();
    const navigate = useNavigate();
    const [additionalPaymentError, setAdditionalPaymentError] = useState(false);

    useEffect(() => {
        dispatch(getTicketDetails({ ticketId: ticketId, payment_transaction: true }));
        dispatch(getTicketPayments({ ticketId: ticketId }));
    }, [ticketId, dispatch]);

    const [isEditingRow, setIsEditingRow] = useState([]);
    const [basePaymentScheduledOnDate, setBasePaymentScheduledOnDate] = useState();
    const [approvePaymentPopUpData, setApprovePaymentPopUpData] = useState({});
    const { loggedUser } = useSelector((state) => state.auth);
    const [tableLoading, setTableLoading] = useState(false);
    const [rowPaymentsData, setRowPaymentsData] = useState([]); // created to use form data in TicketBankTransferPayment

    const [disableApproveSignoffButton, setDisableApproveSignoffButton] = useState(false);

    let { ticketDetails, ticketBasePayment, ticketPayment, processAdditionalResponse, requestAdditionalAmountStatus } = useSelector((state) => state.tickets);

    const { approveWalletLoading, formButtonLoading, isTicketPaymentLoading } = useSelector((state) => state.loading);

    const defaultValues = { [arrayName]: [] };
    const approvalPermission = isApprovePaymentAllowed(loggedUser.frontend_controller);

    const [tableHeaderName, setTableHeaderName] = useState();
    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue, watch, getValues } = methods;

    useEffect(() => {
        if (processAdditionalResponse) {
            const { rejected, approved, idx } = processAdditionalResponse;
            const editPayout = watch(arrayName);
            const updatedEditPayout = editPayout?.map((payout, payoutIdx) => {
                if (idx === payoutIdx) {
                    if (approved) {
                        return {
                            ...payout,
                            status: 'isApproved',
                        };
                    } else if (rejected) {
                        return {
                            ...payout,
                            status: 'isRejected',
                        };
                    }
                }
                return payout;
            });
            setValue(arrayName, updatedEditPayout);
        }
    }, [processAdditionalResponse, setValue, watch]);

    useEffect(() => {
        if (requestAdditionalAmountStatus) {
            const { status, idx } = requestAdditionalAmountStatus;
            const editPayout = watch(arrayName);
            const updatedEditPayout = editPayout?.map((payout, payoutIdx) => {
                if (idx === payoutIdx && status) {
                    return {
                        ...payout,
                        isScheduled: true,
                        isDeletable: false,
                        isEditable: false,
                        statusColor: 'scogoorange',
                        statusContent: 'Scheduled',
                    };
                }
                return payout;
            });
            setValue(arrayName, updatedEditPayout);
            setIsEditingRow((current) => current.filter((id) => id !== idx));
        }
    }, [requestAdditionalAmountStatus, setValue, watch]);

    const getHeaderName = useCallback(() => {
        let headerNames = {
            customer: { name: `Customer` },
            partner: { name: `Partner` },
            cluster: { name: `Cluster` },
            psp: { name: `PSP` },
            supervisor: { name: `Supervisor` },
            warehouse_owner: { name: `Warehouse Owner` },
        };

        if (ticketDetails?.customer_details?.customer_company_name) {
            headerNames[`customer`] = {
                name: `${ticketDetails?.customer_details?.customer_company_name}`,
                id: ticketDetails?.customer_details?.fk_user_id,
            };
        }
        if (ticketDetails?.sp_first_name) {
            headerNames[`partner`] = { name: `${ticketDetails?.sp_first_name} ${ticketDetails?.sp_last_name}`, id: ticketDetails?.accepted_sp_id, is_pancard_linked_with_aadhaar: ticketDetails?.accepted_s_p_company_detail?.is_pancard_linked_with_aadhaar };
        }
        if (ticketDetails?.warehouse_owner_details?.first_name) {
            headerNames[`warehouse_owner`] = {
                name: `${ticketDetails.warehouse_owner_details.first_name} ${ticketDetails.warehouse_owner_details.last_name}`,
                id: ticketDetails?.warehouse_owner,
                is_pancard_linked_with_aadhaar: ticketDetails?.warehouse_owner_details?.is_pancard_linked_with_aadhaar
            };
        }
        if (ticketDetails?.psp_details?.first_name) {
            headerNames[`psp`] = { name: `${ticketDetails?.psp_details?.first_name} ${ticketDetails?.psp_details?.last_name} `, id: ticketDetails?.psp_id, is_pancard_linked_with_aadhaar: ticketDetails?.psp_details?.is_pancard_linked_with_aadhaar };
        }
        if (ticketDetails?.supervisor_details) {
            headerNames[`supervisor`] = {
                name: `${ticketDetails?.supervisor_details?.first_name} ${ticketDetails?.supervisor_details?.last_name} `,
                id: ticketDetails?.supervisor_id,
                is_pancard_linked_with_aadhaar: ticketDetails?.supervisor_details?.is_pancard_linked_with_aadhaar
            };
        }
        if (ticketDetails?.cl_details?.first_name) {
            headerNames[`cluster`] = { name: `${ticketDetails?.cl_details.first_name} ${ticketDetails?.cl_details.last_name}`, id: ticketDetails?.cluster_id, is_pancard_linked_with_aadhaar: ticketDetails?.cl_details?.is_pancard_linked_with_aadhaar };
        }

        return headerNames;
    }, [ticketDetails]);

    useEffect(() => {
        if (Array.isArray(ticketPayment)) {
            setAdditionalPaymentError(ticketPayment.length === 0);
        } else {
            setAdditionalPaymentError(true);
        }
    }, [ticketPayment])

    useEffect(() => {
        setTableLoading(true);
        let additionalData = [],
            basePaymentData = {};
        if (ticketPayment && ticketPayment?.length > 0) {
            additionalData = getGroupByAdditonalData({ ticketPayment, ticketDetails, setDisableApproveSignoffButton, approvalPermission });
        }
        if (ticketDetails && Object.keys(ticketDetails).length > 0) {
            basePaymentData = getBasePaymentData({ ticketDetails, ticketBasePayment, setBasePaymentScheduledOnDate });
        }
        let headerNames = getHeaderName();
        setTableHeaderName((prevState) => {
            return {
                ...prevState,
                ...headerNames,
            };
        });
        setAdditionalPaymentError(additionalData.length === 0);
        setValue(arrayName, [basePaymentData, ...additionalData]);
        setRowPaymentsData([basePaymentData, ...additionalData]);
        setTableLoading(false);
    }, [ticketPayment, ticketDetails, ticketBasePayment, approvalPermission, getHeaderName, setValue]);

    useEffect(() => {
        return () => {
            dispatch(cleanupTicketDetails());
            dispatch(cleanupTicketPaymentDetails());
            setValue(arrayName, []);
        };
    }, [dispatch, setValue]);

    const submit = (formValues) => {
        console.log('hit');
    };

    const closeModal = (e) => {
        e.preventDefault();
        dispatch(closeModalAction());
        return () => {
            dispatch(cleanupTicketDetails());
            dispatch(cleanupTicketPaymentDetails());
            setValue(arrayName, []);
        };
    };

    const approveSignOffHandler = ({ work_quality_rating, work_quality_comment, sign_off_quality_rating, sign_off_quality_comment, formName }) => {
        const formValues = getValues();
        let isCLuster = ticketDetails?.cluster_id > 0 && !isServiceParnter;
        let isPsp = ticketDetails?.psp_id > 0 && !isServiceParnter;
        let isSuperVisor = ticketDetails?.supervisor_id > 0 && !isServiceParnter;
        let isWarehouseOwner = ticketDetails?.is_rma_raised === 1 && ticketDetails?.warehouse_owner > 0;
        let isCustomer = !isServiceParnter;

        let payload = getApproveSignOffTicketPayload({ data: formValues.editPayout, isCustomer, isWarehouseOwner, isPsp, isCLuster, isSuperVisor });
        payload.ticket_id = ticketId;
        payload.pm_site_status = actualValueForDropdown(formValues.pm_site_status);
        payload.pm_site_status_remark = formValues.pm_site_status_remark;
        if (work_quality_rating) payload.work_quality_rating = work_quality_rating;
        if (work_quality_comment) payload.work_quality_comment = work_quality_comment;
        if (sign_off_quality_rating) payload.sign_off_quality_rating = sign_off_quality_rating;
        if (sign_off_quality_comment) payload.sign_off_quality_comment = sign_off_quality_comment;

        dispatch(approveTicketSignOff({ data: payload, isRefresh: false, navigateBack: true, navigate, closeModal: true, formName }));
    };

    const onBasePaymentChange = (e, idx) => {
        let formScheduledOn = e?.target?.value;
        if (isDateValid(formScheduledOn) && idx === 0) {
            setApprovePaymentPopUpData({ scheduled_on: formScheduledOn, scogo_transaction_no: ticketBasePayment?.scogo_transaction_no });
            let actualBasePaymentScheduledOn = new Date(basePaymentScheduledOnDate).toLocaleDateString();
            let updatedScheduledOn = new Date(formScheduledOn).toLocaleDateString();
            if (actualBasePaymentScheduledOn === updatedScheduledOn) console.log('Same');
            else approveRef.current?.click();
        }
    };

    const onApproveClick = (event) => {
        event.preventDefault();
        const payload = {
            scogo_transaction_no: ticketBasePayment?.scogo_transaction_no,
            action: 'Approve',
        };
        dispatch(approveWalletPayment({ data: payload, isRefresh: false, navigateBack: true, navigate }));
    };
    const onSignOffApproveClick = () => {
        const formValues = getValues();

        if (!actualValueForDropdown(formValues.pm_site_status) || !formValues.pm_site_status_remark) {
            dispatch(sendToastMessage({ message: 'Site Status and Site Remark Is Required', status: 'warning' }));
            return;
        }
        let heading = `Rate your Engineer : ${ticketDetails?.fe_first_name} ${ticketDetails?.fe_last_name}`;
        let modalComponent = <FeRating callOnFormSubmit={approveSignOffHandler} submitButtonText={'Submit'} ticketId={ticketId} />;

        let modalWidth = '50rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    };


    const showDirectBankTransfer = ticketDetails && isDirectBankTransferAllowed(loggedUser.frontend_controller) && (isScm(loggedUser.role.id, loggedUser.type) || (isCustomerGroup(loggedUser.role.id, loggedUser.type) && loggedUser.manage_partner_payments));

    return (
        <div className='bg-white w-full approvePOContainer rounded-lg shadow-lg relative flex overflow-hidden'
            style={{
                flexFlow: 'column',
            }}>
            {(additionalPaymentError && !isTicketPaymentLoading) && (
                <div className='px-6 py-2 flex-initial'>
                    <NoResultFound message={'No Additional Payments Requested Yet'} type={'danger'} />
                </div>
            )}
            <div className='flex-initial flex justify-between items-center' >
                <p className='mx-5 my-2 text-scogogray'>*All payments from Scogo are made post deduction of TDS and System Charges</p>
                {ticketDetails?.distance_travelled?.length > 0 ?
                    <div className='m-5'>
                        <StatusLabel hoverText={`${ticketDetails?.distance_travelled}`} titleText='Distance Travelled by Engineer ' content={`Dist Travelled : ${ticketDetails?.distance_travelled}`} color={'scogoprimary'} labelClass='text-gray-900' />
                    </div>
                    : <></>}
            </div>
            <div className='flex-auto h-full overflow-hidden' ref={formDivRef}>
                <Form
                    methods={methods}
                    className='flex flex-col h-full'
                    submit={(e) => {
                        submit(e);
                    }}
                    onCancel={closeModal}
                    customFormButtons={true}
                    isLoading={formButtonLoading[formName]}
                >
                    <div className='flex flex-col h-full overflow-x-scroll '>
                        {showApproveSignOff && (
                            <div className='flex-intial px-7 grid grid-cols-2 rows-fr grid-flow-row-dense items-end'>
                                <Select type='dropdown' name={`pm_site_status`} required label='Site Status' options={siteStateOptions} />
                                <TextArea type='text' name={`pm_site_status_remark`} required label='Site Remark' />
                            </div>
                        )}
                        <div className='flex-auto overflow-y-auto w-fit min-w-full'>
                            <EditPayoutTable
                                onBasePaymentChange={onBasePaymentChange}
                                tableHeaderName={tableHeaderName}
                                isEditingRow={isEditingRow}
                                showAdditionalAmountApprove={showAdditionalAmountApprove}
                                ticketBasePayment={ticketBasePayment}
                                setIsEditingRow={setIsEditingRow}
                                ticketId={ticketId}
                                isServiceParnter={isServiceParnter}
                                arrayName={arrayName}
                                tableLoading={tableLoading}
                            />
                        </div>
                    </div>
                </Form>
                <div className='flex items-center gap-2 relative'>
                    <MiniPopup
                        handleClick={() => { }}
                        popup={<ApprovePayment scogo_transaction_no={approvePaymentPopUpData?.scogo_transaction_no} scheduled_on={approvePaymentPopUpData?.scheduled_on} />}
                        transition={false}
                        className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-scroll left-0 top-0'
                        menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <div ref={approveRef} />
                    </MiniPopup>
                </div>
            </div>
            <div className='flex-initial approvePOButtons flex gap-2 items-center bg-white px-7 rounded-b-lg justify-end '>
                {showAdditionalAmountApprove && (
                    <ButtonScogoPrimary
                        textOrComponent={'Approve'}
                        onClick={(e) => onApproveClick(e)}
                        loading={approveWalletLoading}
                        disabled={disableApproveSignoffButton}
                        buttonIcon={'/img/plus.svg'}
                    />
                )}
                {showDirectBankTransfer ? <TicketBankTransferPayment ticketId={ticketId} rowPaymentsData={rowPaymentsData} /> : <></>}
                {showApproveSignOff && <RenderAddConsumables ticketId={ticketId} />}
                {showApproveSignOff && <ButtonScogoPrimary textOrComponent={'Approve Sign Off'} onClick={onSignOffApproveClick} buttonIcon={'/img/plus.svg'} />}
                <ButtonScogoClosedOutlined textOrComponent={'Cancel'} onClick={onCancel} />
            </div>
        </div>
    );
};

export default EditPayout;

export const getApproveSignOffTicketPayload = ({ data, isCustomer, isWarehouseOwner, isPsp, isCLuster, isSuperVisor }) => {
    let payload = {};
    data.forEach((item) => {
        if (item?.referenceId && item.type === 'Additional Payment') {
            if (isCustomer) {
                payload[`customer_amount_${item.referenceId}`] = item.customer_details;
                payload[`customer_transaction_id_${item.referenceId}`] = item.customer_beneficiary_transaction_id;
            }

            if (isWarehouseOwner) {
                payload[`warehouse_amount_${item.referenceId}`] = item.warehouse_details;
                payload[`warehouse_transaction_id_${item.referenceId}`] = item.warehouse_owner_beneficiary_transaction_id;
            }

            if (isPsp) {
                payload[`psp_amount_${item.referenceId}`] = item.psp_details;
                payload[`psp_transaction_id_${item.referenceId}`] = item.psp_beneficiary_transaction_id;
            }
            if (isCLuster) {
                payload[`cluster_amount_${item.referenceId}`] = item.cluster_details;
                payload[`cluster_transaction_id_${item.referenceId}`] = item.cluster_beneficiary_transaction_id;
            }

            if (isSuperVisor) {
                payload[`supervisor_amount_${item.referenceId}`] = item.supervisor_details;
                payload[`supervisor_transaction_id_${item.referenceId}`] = item.supervisor_beneficiary_transaction_id;
            }

            payload[`category_${item.referenceId}`] = item.category;
            payload[`remark_${item.referenceId}`] = item.remark;
            payload[`proof_${item.referenceId}`] = item.proof;
            payload[`partner_amount_${item.referenceId}`] = item.partner_details;
            payload[`partner_transaction_id_${item.referenceId}`] = item.partner_beneficiary_transaction_id;
        }
        if (item.type === 'Base Payment') {
            if (isCustomer) payload.customer_base_payment = item.customer_details;
            if (isWarehouseOwner) payload.warehouse_base_payment = item.warehouse_details;
            if (isPsp) payload.psp_base_payment = item.psp_details;
            if (isCLuster) payload.cluster_base_payment = item.cluster_details;
            if (isSuperVisor) payload.supervisor_base_payment = item.supervisor_details;
            payload.partner_base_payment = item.partner_details;
        }
    });

    return payload;
};

